import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Col, Typography, Layout, Table, message, Button } from 'antd'
import { Link, Redirect } from 'react-router-dom';
import { url } from '../../config/global'
import Axios from 'axios';
import moment from 'moment';
import './DashboardPantry.less'

const { Title, Text } = Typography
const { Content } = Layout
const { Meta } = Card;


const DashboardPantry = () => {

    const [data, setData] = useState([])
    // const [buttonAccess, setButtonAccess] = useState(null);
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData(date)
    }, [date])

    const getData = (date) => {
        setLoading(true)
        Axios.get(`${url}/pantryDash`, {
            params: {
                date: date
            },
        }).then(res => {
            // console.log(res.data)
            setData(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const confirmData = (meeting_id, id) => {

        setLoading(true)
        Axios.post(`${url}/pantryConfirm`, {
            meeting_id: meeting_id,
            id: id
        }).then(res => {

            message.success(res.data.message)

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
            getData(date)
        })

    }

    const columns = [
        {
            title: 'Start Meeting',
            dataIndex: 'start_time',
            key: 'start_time',
        },
        {
            title: 'End Meeting',
            dataIndex: 'end_time',
            key: 'end_time',
        },
        {
            title: 'Meeting Rooms',
            key: 'room_name',
            dataIndex: 'room_name',
        },
        {
            title: 'Order',
            dataIndex: 'pantries',
            render: ((e, ooo, xxx) => {
                if (ooo.pantries.length > 0) {
                    return e.map((v, i) => {
                        return <tr key={v.id} className='orderStyle'>
                            <td>
                                {v.name}
                            </td>
                            <td style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                {v.qty}
                            </td>
                            <td style={{ marginLeft: 20 }}>
                                {
                                    v.status === 0 ? <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => confirmData(ooo._id, v.id)}
                                    >
                                        Confirm
                                    </Button> : <Button
                                        type="primary"
                                        disabled={true}
                                        size="small"
                                    >
                                        Confirm
                                    </Button>
                                }

                            </td>
                        </tr>
                    })
                } else {
                    return 'No Order'
                }
            })
        },
        {
            title: 'PIC',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Departement',
            key: 'department',
            dataIndex: 'department',
        },
        {
            title: 'Ext',
            key: 'room_ext',
            dataIndex: 'room_ext',
        },

    ];

    console.log(JSON.stringify(data, null, 4))

    return (
        <Fragment>
            <Row className='dash-pantry'>
                <Col xs={24}>
                    <Card type="inner" title="Pantry Dashboard" extra={date} bodyStyle={{padding: "0"}}></Card>
                </Col>
            </Row>
            <Content className='content dash-pantry'>
                <Row>
                    <Col md={24} sm={24} xs={24}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            showHeader={true}
                            rowKey={(data) => data._id}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default DashboardPantry
