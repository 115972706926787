import React, { Fragment, useContext, useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, message, Popconfirm, Table } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global';
import './Priority.less'

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
	labelCol: {
		lg: 8,
		md: 10
	},
	wrapperCol: {
		lg: 8,
		md: 14
	},
}
const tailLayout = {
	wrapperCol: {
		offset: 0,
		span: 16,
	},
}

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};

	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleSave({ ...record, ...values });
		} catch (errInfo) {
			// console.log('Save failed:', errInfo);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{
					margin: 0,
				}}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} is required.`,
					},
				]}
			>
				<Input 
					ref={inputRef} 
					onPressEnter={save} 
					onBlur={save}
				/>
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingRight: 24,
				}}
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

class CreatePriority extends React.Component {
	constructor(props) {
		super(props);
		
		this.columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				editable: true,
			},
			{
				title: 'Action',
				dataIndex: 'operation',
				render: (_, record, index) =>
					index == 0 ? null : (
						<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
							<Button
								type="primary"
								className='btn'
							>
								Delete
							</Button>
						</Popconfirm>
					),
			},
		];

		this.state = {
			data: {
				priority_id: '',
				priority_level: ''
			},
			level: [],
			error: '',
			loading: '',
			dataSource: [],
			count: 1
		};

	}
	
	componentDidMount(){
		this.getAllLevel()
	}

	getAllLevel = () => {
		Axios.get(`${url}/settings/find`, {
			params: {
				variable: "parent_level_priority",
			},
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
		}).then(res => {
				this.setState({level: res.data.data})
		}).catch(err => {
				if (err.response) {
						message.error(err.response.data.message)
						if (err.response.status == 401) {
								localStorage.removeItem('token')
						}
				}
		})
	}

	handleDelete = (key) => {
		const dataSource = [...this.state.dataSource];
		this.setState({
			dataSource: dataSource.filter((item) => item.key !== key),
		});
	};

	handleAdd = () => {
		const { count, dataSource } = this.state;
		const newData = {
			key: this.state.count.toString(),
			valid: false,
			name: 'Entry Here !',
			// age: '32',
			// address: `London, Park Lane no. ${count}`,
		};
		this.setState({
			dataSource: [...dataSource, newData],
			count: count + 1,
		});
	};
	
	handleSave = (row) => {
		const newData = [...this.state.dataSource];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index]
		const newArrow = {
			...row,
			valid: row.name !== ''
		}
		newData.splice(index, 1, { ...item, ...newArrow });
		this.setState({
			dataSource: newData,
		});
	};

	submithandler = async () => {
		this.setState({loading: true})
		try {
			if (!this.state.data.priority_level && !this.state.data.priority_id)  {
					message.error('Please choose priority')
					this.setState({loading: false})
			} else {
				const res = await Axios.post(`${url}/priority`,{
					priority_id: this.state.data.priority_id,
					priority_level: this.state.data.priority_level,
					data: this.state.dataSource
				}, {
						headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`
						}
				})
				this.setState({loading: false})
				message.success(res.data.message)
				this.props.history.push('/priority')
			}
		} catch (err) {
			if (err.response) {
				message.error(err.response.data.message)
				if (err.response.status === 422) {
						this.setState({
							data: {
							err: err.response.data.errors
						}})
				}
				if (err.response.status == 401) {
						localStorage.removeItem('token')
				}
		}
		this.setState({loading: false})
		}
	}

	handleChange = (name, value) => {
		this.setState(prevState => { 
			return {
				...prevState ,
				error: null,
				data: {
					...prevState.data,
					[name]: value
				}
			}
		})
	}

	handleChangeSelect = (name, value) => {
		this.setState(prevState => {
			return {
				...prevState,
				error: null,
				data: {
					...prevState.data,
					[`${name}_id`]: value.value,
					[`${name}_level`]: value.label
				}
			}
		})
	}
	
	render() {
		const { dataSource } = this.state;
		const components = {
			body: {
				row: EditableRow,
				cell: EditableCell,
			},
		};
		const columns = this.columns.map((col) => {
			if (!col.editable) {
				return col;
			}

			return {
				...col,
				onCell: (record) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					handleSave: this.handleSave,
				}),
			};
		});
		return (
			<Fragment>
					<Row>
						<Col xs={24}>
								<Card type="inner" title="Priority" extra={moment(new Date()).format('DD MMMM YYYY')}>
										<Row type="flex" justify="left">
												<Breadcrumb>
														<Breadcrumb.Item>
																<Link to="/priority">Back</Link>
														</Breadcrumb.Item>
														<Breadcrumb.Item>Add Priority</Breadcrumb.Item>
												</Breadcrumb>
										</Row>
								</Card>
						</Col>
				</Row>
				<Content className='content'>
			<Card>
				<Form 
					{...layout}
					name="control-hooks"
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 17 }}  
					layout='vertical'
					onFinish={this.handleSave}
				>
					<Row>
						<Col lg={9} md={9} sm={24} xs={24}>
						<Form.Item
							required
							label="Priority"
							rules={[{required: true, message: 'Please choose priority'}]}
							validateStatus={this.state.error && this.state.error.priority_id ? 'error' : false }
							help={this.state.error && this.state.error.priority_id ? this.state.error.priority_id[0] : false }
						>
							<Select 
								labelInValue
								onChange={(value) => this.handleChangeSelect('priority', value)}
								onFocus={() => this.getAllLevel('')}
								showSearch 
								onSearch={(value) => this.getAllLevel(value)} 
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={{ key: this.state.data.priority_id, label: this.state.data.priority_level }}
							>
								{
									this.state.level && this.state.level.map(role => {
										return <Option value={role.id} key={role.id}>{role.name}</Option>
									})
								}

							</Select>
						</Form.Item>
						</Col>
					</Row>
					<Row style={{marginBottom: 50}}>
						<Col>
							<Button
								onClick={this.handleAdd}
								type="primary"
								className='btn'
							>
							<PlusOutlined/>Add Row
							</Button>
						</Col>
					</Row>
					<Row>
						<Col xs={24} style={{overflowX: 'auto'}}>
							<Table
								components={components}
								rowClassName={() => 'editable-row'}
								bordered
								dataSource={dataSource}
								columns={columns}
							/>
						</Col>
					</Row>
					<Row style={{marginTop: 30}}>
						<Col lg={24} md={24} sm={24} xs={24} >
							<Form.Item {...tailLayout}>
								<Button 
									loading={this.state.loading} 
									disabled={this.state.dataSource.findIndex(item => item.valid == false) > -1}
									type="primary" 
									onClick={this.submithandler}
									className="btn"
								>
									Submit
								</Button>
								{/* <Button 
									htmlType="reset" 
									className='btn'
									// onClick={handleReset}
								>
									Reset
								</Button> */}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>
				</Content>
			</Fragment>
		);
	}
}

export default CreatePriority