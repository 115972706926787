import React, { useState, useEffect } from 'react'
import { Input, Button, message, Form, Row, Col } from 'antd'
import './ForgotPassword.less'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import Axios from 'axios'
import { url } from '../../config/global'

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
}

function ChangePassword(props) {
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState({
        password: '',
        confirm_password: ''
    })

    //#region handle get data
    useEffect(() => {
        getData()
    }, [])


    const getData = () => {
        Axios.get(`${url}/forgot-password/${props.match.params.token}`).then(res => {

            const resData = res.data.data

            // console.log(resData)

            setData({
                ...data,
                username: resData.username,
                email: resData.email,
                token: resData.reset_token,
                password: '',
                password_confirmation: '',
            })

            setLoading(false)

        }).catch(err => {

            if (err.response) {

                message.error('Invalid Token.')
            }

            setLoading(false)

        })
    }
    //#endregion

    const handleChange = (name, value) => {
        setData({
            ...data,
           [name]: value
        })
    }

    const handleReset = () => {
        setData({
            password: '',
            confirm_password: ''
        })
    }

    const handleSubmit = async () => {

        setLoading(true)

        let formData = new FormData()
        formData.set('email', data.email)
        formData.set('token', data.token)
        formData.set('password', data.password)
        formData.set('password_confirmation', data.password_confirmation)

        await Axios.post(`${url}/forgot-password/${props.match.params.token}`, formData).then(res => {

            message.success(res.data.message)
            setLoading(false)
            if (props.match.params.token != null){
                
                props.history.push({
                    pathname:'/login',
                    state: {username: data.username},
                }) 

            } else {
                handleChange('email', '')
            }

        }).catch(err => {

            message.error(err.response.data.message)

            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }

            setLoading(false)

        })
    }

    return (
        <div className='main'>
            <Row justify='center'>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <img    
                        src={require('../../assets/img/logo-login.png')}
                        alt='bg-login'
                        className='image-logo'
                    />
                </Col>
            </Row> 
            <Row justify='center'>
                <Col lg={6} md={10} sm={24} xs={24} >
                    <div className='signin-text'>Change Password</div>
                    <Form 
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            required
                            validateStatus={error && error.email ? 'error' : false }
                            help={error && error.email ? error.email[0] : false }
                            >
                                <Input
                                    className="login-input"
                                    placeholder="Email"
                                    value={data.email}
                                    type='email'
                                    readOnly={true}
                                />
                        </Form.Item>
                        <Form.Item
                            required
                            validateStatus={error && error.token ? 'error' : false }
                            help={error && error.token ? error.token[0] : false }
                            >
                                <Input 
                                    className="login-input"
                                    placeholder="Token"
                                    value={data.token}
                                    type='text'
                                    readOnly={true}
                                />
                        </Form.Item>
                        <Form.Item
                            required
                            validateStatus={error && error.password ? 'error' : false }
                            help={error && error.password ? error.password[0] : false }
                            >
                                <Input.Password 
                                    className="login-input"
                                    placeholder="New password"
                                    onChange={(e) => handleChange('password',e.target.value)}
                                    value={data.password}
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    type='password'
                                />
                        </Form.Item>
                        <Form.Item
                            required
                            validateStatus={error && error.password_confirmation ? 'error' : false }
                            help={error && error.password_confirmation ? error.password_confirmation[0] : false }
                            >
                                <Input.Password 
                                    className="login-input"
                                    placeholder="Confirm new password"
                                    onChange={(e) => handleChange('password_confirmation',e.target.value)}
                                    value={data.password_confirmation}
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    type='password'
                                />
                        </Form.Item>
                        <Row justify='space-between'>
                            <Col lg={11} md={11} sm={11} xs={11}>
                                <Button htmlType='submit' loading={loading} type="primary" className='btn-submit' >
                                    Submit
                                </Button>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1} />
                            <Col lg={11} md={11} sm={11} xs={11}>
                                <Button htmlType="reset" className='btn-reset' onClick={handleReset}>
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default ChangePassword
