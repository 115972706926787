import React, { useState, useEffect, Fragment } from 'react';
import { Card, Typography, Layout, Row, Col, Input, message } from 'antd';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global';
import './RoomReservation.less';
import dummyImg from '../../assets/img/dummy_img.jpg';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { Meta } = Card;


function RoomReservation(props) {

    const [keyword, setKeyword] = useState('')
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })

    const handleSearch = (value) => {
        setKeyword(value)
        setPage(1)
    }

    useEffect(() => {
        getData(keyword, page, sort)
    }, [keyword, page, sort])

    const getData = (keyword, page, sort) => {
        setLoading(true)
        Axios.get(`${url}/room`, {
            params: {
                keyword,
                columns: [
                    'name',
                    'description'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setRooms(res.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const onDetail = (id) => {
        props.history.push(`/room-reservation/list/${id}`)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Meeting Room Reservation" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>

            <Content className="content-card">
                <div className='wrapperBtn'>
                    <div className='left'>
                    </div>
                    <div className='right'>
                        <Search
                            placeholder="Search ..."
                            // onSearch={(value) => handleSearch(value)}
                            onChange={(e) => handleSearch(e.currentTarget.value)}
                        />
                    </div>
                </div>
                <Row>
                    {
                        rooms.room && rooms.room.map((res) => {
                            return (
                                <Col lg={12} md={12} sm={24} xs={24} className='col-card' key={res._id.toString()}>
                                    <Card className="body-data-room"
                                        hoverable
                                        cover={<img alt='pict-room' src={res.image_url ? res.image_url : dummyImg} style={{ width: '100%', height: 300, objectFit: 'cover' }} />}
                                        loading={loading}
                                        onClick={() => onDetail(res._id)}
                                    >
                                        <h2>{res.name}</h2>
                                        <div className='capacity-text'>{res.description}</div>
                                        <div className='wrapper-status'>
                                            <div style={{ marginRight: 5 }} className='status'>Status:</div>
                                            {
                                                res.status !== 1 ?
                                                    <div className='status-inactive'>Available</div> :
                                                    <div className='status-active'>In Used</div>
                                            }
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Content>
        </Fragment>
    )
}



export default RoomReservation
