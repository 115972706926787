import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';
import moment from 'moment';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function EditExternalParticipants(props) {

    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        type: '',
        department: ''
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {

        Axios.get(`${url}/externalparticipants/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {

            // console.log(res.data)
            setData({
                ...data,
                name: res.data.data.name,
                email: res.data.data.email,
                phone: res.data.data.phone,
                company: res.data.data.company,
                address: res.data.data.address,
                department: res.data.data.department,
            })

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }

        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handlePhoneNumber = (value) => {

        setError(null)

        if (value && isValidPhoneNumber(value)) {

            setData({
                ...data,
                phone: value
            })

        } else {
            setError({
                phone: [
                    'Invalid phone number'
                ]
            })
        }
    }

    function handleChangeSelect(value) {
        setError(null)
        setData({
            ...data,
            type: value.toString()
        })
    }

    const onSaveData = () => {

        setLoading(true)


        Axios.post(`${url}/externalparticipants/${props.match.params.id}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/external-participants')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            name: '',
            email: '',
            phone: '',
            company: '',
            address: '',
            type: '',
            department: ''
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="External Participants" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/external-participants">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit External Participants</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form
                                {...layout}
                                name="control-hooks"
                                onFinish={onSaveData}
                            >
                                <Row gutter={[15, 0]}>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            required
                                            label="Name"
                                            labelAlign='left'
                                            validateStatus={error && error.name ? 'error' : false}
                                            help={error && error.name ? error.name[0] : false}
                                        >
                                            <Input value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
                                        </Form.Item>
                                        <Form.Item
                                            required
                                            label="Email"
                                            labelAlign='left'
                                            validateStatus={error && error.email ? 'error' : false}
                                            help={error && error.email ? error.email[0] : false}
                                        >
                                            <Input value={data.email} onChange={(e) => handleChange('email', e.target.value)} />
                                        </Form.Item>
                                        <Form.Item
                                            required
                                            label="Phone"
                                            labelAlign='left'
                                            validateStatus={error && error.phone ? 'error' : false}
                                            help={error && error.phone ? error.phone[0] : false}
                                        >
                                            <PhoneInput
                                                className="ant-input"
                                                international
                                                withCountryCallingCode
                                                onChange={(e) => handlePhoneNumber(e)}
                                                value={data.phone}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            required
                                            label="Type"
                                            labelAlign='left'
                                            validateStatus={error && error.type ? 'error' : false}
                                            help={error && error.type ? error.type[0] : false}
                                        >
                                            <Select defaultValue={data.type} value={data.type} onChange={handleChangeSelect}>
                                                <Option value="0" key="0">Internal</Option>
                                                <Option value="1" key="1">External</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item

                                            label="Company"
                                            labelAlign='left'
                                            validateStatus={error && error.company ? 'error' : false}
                                            help={error && error.company ? error.company[0] : false}
                                        >
                                            <Input value={data.company} onChange={(e) => handleChange('company', e.target.value)} />
                                        </Form.Item>
                                        <Form.Item

                                            label="Address"
                                            labelAlign='left'
                                            validateStatus={error && error.address ? 'error' : false}
                                            help={error && error.address ? error.address[0] : false}
                                        >
                                            <Input value={data.address} onChange={(e) => handleChange('address', e.target.value)} />
                                        </Form.Item>
                                        <Form.Item

                                            label="Department"
                                            labelAlign='left'
                                            validateStatus={error && error.department ? 'error' : false}
                                            help={error && error.department ? error.department[0] : false}
                                        >
                                            <Input value={data.department} onChange={(e) => handleChange('department', e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 30 }}>
                                    <Col lg={24} md={24} sm={24} xs={24} >
                                        <Form.Item {...tailLayout}>
                                            <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                                Submit
                                            </Button>
                                            <Button htmlType="reset" onClick={handleReset}>
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>

            </Content>
        </Fragment>
    )
}

export default EditExternalParticipants