import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import routes from './routes'
import Error404 from './views/Errors/Error404'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas, fab, far)

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {
          routes.map((route, index) => {
            return (
              <Route key={index} path={route.path} exact={route.exact} component={(props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                )
              }} />
            )
          })
        }
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  )
}

export default App

