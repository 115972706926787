import React from 'react'
import BasicLayout from "./layouts/BasicLayout";
import Login from "./views/Login/Login";
import { Redirect } from "react-router-dom";
import FullLayout from './layouts/FullLayout';
import Dashboard from './views/Dashboard/Dashboard';
import User from './views/User/User';
import CreateUser from './views/User/CreateUser';
import EditUser from './views/User/EditUser';
import Permission from './views/Permission/Permission';
import CreatePermission from './views/Permission/CreatePermission';
import EditPermission from './views/Permission/EditPermission';
import ViewPermission from './views/Permission/ViewPermission';
import Role from './views/Role/Role';
import CreateRole from './views/Role/CreateRole';
import EditRole from './views/Role/EditRole';
import Setting from './views/Setting/Setting';
import CreateSetting from './views/Setting/CreateSetting';
import EditSetting from './views/Setting/EditSetting';
import MeetingList from './views/MeetingList/MeetingList';
import Pantry from './views/DashboardPantry/DashboardPantry'
import Priority from './views/Priority/Priority'
import MeetingRoom from './views/MeetingRoom/MeetingRoom'
import RoomReservation from './views/RoomReservation/RoomReservation'
import ExternalParticipants from './views/ExternalParticipants/ExternalParticipants'
import MasterPantry from './views/MasterPantry/MasterPantry';
import DashboardPantry from './views/DashboardPantry/DashboardPantry';
import CreateMeetingRoom from './views/MeetingRoom/CreateMeetingRoom';
import EditMeetingRoom from './views/MeetingRoom/EditMeetingRoom';
import CreatePriority from './views/Priority/CreatePriority';
import EditPriority from './views/Priority/EditPriority';
import CreateExternalParticipants from './views/ExternalParticipants/CreateExternalParticipants';
import EditExternalParticipants from './views/ExternalParticipants/EditExternalParticipants';
import CreateMasterPantry from './views/MasterPantry/CreateMasterPantry';
import EditMasterPantry from './views/MasterPantry/EditMasterPantry';
import CreateRoomReservation from './views/RoomReservation/CreateRoomReservation';
import ViewUser from './views/User/ViewUser';
import ViewExternalParticipants from './views/ExternalParticipants/ViewExternalParticipants';
import ViewMasterPantry from './views/MasterPantry/ViewMasterPantry';
import ViewMeetingRoom from './views/MeetingRoom/ViewMeetingRoom';
import ViewPriority from './views/Priority/ViewPriority';
import ListRoomReservation from './views/RoomReservation/ListRoomReservation';
import ViewRoomReservation from './views/RoomReservation/ViewRoomReservation';
import Sso from "./views/Sso/Sso";
import EditRoomReservation from './views/RoomReservation/EditRoomReservation';
import Register from './views/Register/Register';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ChangePassword from './views/ForgotPassword/ChangePassword';
//import WarehouseReceiving from './views/WarehouseReceiving/WarehouseReceiving';
import QRCodeM from './views/QrCode/Qrcode';

export default [
    {
        path: '/',
        layout: BasicLayout,
        exact: true,
        component: () => <Redirect to="/login" />
    },
    {
        path: '/QRCode/:qrcode',
        layout: BasicLayout,
        component: QRCodeM,
        exact: true,
    },
    {
        path: '/login',
        layout: BasicLayout,
        component: () => { window.location.replace("http://sso.dharmap.com/"); },
        exact: true,
    },
    {
        path: '/vaontdxnht',
        layout: BasicLayout,
        component: Register,
        exact: true,
    },
    {
        path: '/forgot-password',
        layout: BasicLayout,
        component: ForgotPassword,
        exact: true,
    },
    {
        path: '/forgot-password/change/:token',
        layout: BasicLayout,
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/dashboard',
        layout: FullLayout,
        component: Dashboard,
        exact: true
    },
    {
        path: '/dashboard-pantry',
        layout: BasicLayout,
        component: DashboardPantry,
        exact: true
    },
    {
        path: '/meeting-room',
        layout: FullLayout,
        component: MeetingRoom,
        exact: true
    },
    {
        path: '/meeting-room/create',
        layout: FullLayout,
        component: CreateMeetingRoom,
        exact: true
    },
    {
        path: '/meeting-room/view/:id',
        layout: FullLayout,
        component: ViewMeetingRoom,
        exact: true
    },
    {
        path: '/meeting-room/edit/:id',
        layout: FullLayout,
        component: EditMeetingRoom,
        exact: true
    },
    {
        path: '/priority',
        layout: FullLayout,
        component: Priority,
        exact: true
    },
    {
        path: '/priority/create',
        layout: FullLayout,
        component: CreatePriority,
        exact: true
    },
    {
        path: '/priority/view/:id',
        layout: FullLayout,
        component: ViewPriority,
        exact: true
    },
    {
        path: '/priority/edit/:id',
        layout: FullLayout,
        component: EditPriority,
        exact: true
    },
    {
        path: '/external-participants',
        layout: FullLayout,
        component: ExternalParticipants,
        exact: true
    },
    {
        path: '/external-participants/create',
        layout: FullLayout,
        component: CreateExternalParticipants,
        exact: true
    },
    {
        path: '/external-participants/view/:id',
        layout: FullLayout,
        component: ViewExternalParticipants,
        exact: true
    },
    {
        path: '/external-participants/edit/:id',
        layout: FullLayout,
        component: EditExternalParticipants,
        exact: true
    },
    {
        path: '/master-pantry',
        layout: FullLayout,
        component: MasterPantry,
        exact: true
    },
    {
        path: '/master-pantry/create',
        layout: FullLayout,
        component: CreateMasterPantry,
        exact: true
    },
    {
        path: '/master-pantry/view/:id',
        layout: FullLayout,
        component: ViewMasterPantry,
        exact: true
    },
    {
        path: '/master-pantry/edit/:id',
        layout: FullLayout,
        component: EditMasterPantry,
        exact: true
    },
    {
        path: '/room-reservation',
        layout: FullLayout,
        component: RoomReservation,
        exact: true
    },
    {
        path: '/room-reservation/list/:id',
        layout: FullLayout,
        component: ListRoomReservation,
        exact: true
    },
    {
        path: '/room-reservation/create/:id',
        layout: FullLayout,
        component: CreateRoomReservation,
        exact: true
    },
    {
        path: '/room-reservation/view/:id',
        layout: FullLayout,
        component: ViewRoomReservation,
        exact: true
    },
    {
        path: '/room-reservation/edit/:id',
        layout: FullLayout,
        component: EditRoomReservation,
        exact: true
    },
    {
        path: '/meeting-list',
        layout: FullLayout,
        component: MeetingList,
        exact: true
    },
    {
        path: '/user',
        layout: FullLayout,
        component: User,
        exact: true
    },
    {
        path: '/user/create',
        layout: FullLayout,
        component: CreateUser,
        exact: true
    },
    {
        path: '/user/edit/:id',
        layout: FullLayout,
        component: EditUser,
        exact: true
    },
    {
        path: '/user/view/:id',
        layout: FullLayout,
        component: ViewUser,
        exact: true
    },
    {
        path: '/permission',
        layout: FullLayout,
        component: Permission,
        exact: true
    },
    {
        path: '/permission/create',
        layout: FullLayout,
        component: CreatePermission,
        exact: true
    },
    {
        path: '/permission/edit/:id',
        layout: FullLayout,
        component: EditPermission,
        exact: true
    },
    {
        path: '/permission/view/:id',
        layout: FullLayout,
        component: ViewPermission,
        exact: true
    },
    {
        path: '/role',
        layout: FullLayout,
        component: Role,
        exact: true
    },
    {
        path: '/role/create',
        layout: FullLayout,
        component: CreateRole,
        exact: true
    },
    {
        path: '/role/edit/:id',
        layout: FullLayout,
        component: EditRole,
        exact: true
    },
    {
        path: '/setting',
        layout: FullLayout,
        component: Setting,
        exact: true
    },
    {
        path: '/setting/create',
        layout: FullLayout,
        component: CreateSetting,
        exact: true
    },
    {
        path: '/setting/edit/:id',
        layout: FullLayout,
        component: EditSetting,
        exact: true
    },
    {
        path: "/sso",
        layout: BasicLayout,
        component: Sso,
        exact: true,
    },
    {
        path: "/pantry",
        layout: BasicLayout,
        component: Pantry,
        exact: true,
    },
    
    
]