import React, { Fragment, useState } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
}

function CreateUser(props) {

    const [data, setData] = useState({
        username: '',
        full_name: '',
        npk: '',
        division: '',
        department: '',
        position: '',
        email: '',
        phone: '',
        // password: '',
        // password_confirmation: '',
        role_id: '',
        role_name: '',
        refer_id: '',
        refer_name: '',
        photo: null,
        roles: []
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleRemove = () => {
        setData({
            ...data,
            photo: null
        })
    }

    const handleBeforeUpload = (files) => {

        setData({
            ...data,
            photo: files
        })

        return false
    }

    const getRole = async (keyword) => {
        await Axios.get(`${url}/role/list`, {
            params: {
                keyword
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                roles: res.data.data
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handlePhoneNumber = (value) => {

        setError(null)

        if (value && isValidPhoneNumber(value)) {

            setData({
                ...data,
                phone: value
            })

        } else {
            setError({
                phone: [
                    'Invalid phone number'
                ]
            })
        }
    }

    const handleChangeSelect = (name, value) => {
        setError(null)
        setData({
            ...data,
            [`${name}_id`]: value.value,
            [`${name}_name`]: value.label
        })
    }

    const onSaveData = () => {

        // setLoading(true)
        // console.log('ini data nya', data)

        let fd = new FormData()

        fd.set('username', data.username)
        fd.set('npk', data.npk)
        fd.set('email', data.email)
        fd.set('phone', data.phone)
        fd.set('full_name', data.full_name)
        fd.set('division', data.division)
        fd.set('department', data.department)
        fd.set('position', data.position)
        fd.set('role_id', data.role_id)
        fd.set('role_name', data.role_name)
        // fd.set('password', data.password)
        // fd.set('password_confirmation', data.password_confirmation)
        if (data.photo) {
            fd.append('photo', data.photo)
        }

        Axios.post(`${url}/user`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/user')
        }).catch(err => {

            if (err.response) {

                message.error(err.response.data.message)

                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }

                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }

            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            username: '',
            full_name: '',
            npk: '',
            division: '',
            department: '',
            position: '',
            email: '',
            phone: '',
            // password: '',
            // password_confirmation: '',
            role_id: '',
            photo: null,
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="User" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/user">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Add User</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Card className="body-data">
                    <Form
                        {...layout}
                        name="control-hooks"
                        onFinish={onSaveData}
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 17 }}
                    >
                        <Row gutter={[15, 0]}>
                            <Col lg={12} md={12} sm={24} xs={24} >
                                <Form.Item
                                    required
                                    label="Username"
                                    labelAlign='left'
                                    validateStatus={error && error.username ? 'error' : false}
                                    help={error && error.username ? error.username[0] : false}
                                >
                                    <Input value={data.username} onChange={(e) => handleChange('username', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Full Name"
                                    labelAlign='left'
                                    validateStatus={error && error.full_name ? 'error' : false}
                                    help={error && error.full_name ? error.full_name[0] : false}
                                >
                                    <Input value={data.full_name} onChange={(e) => handleChange('full_name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="NPK"
                                    labelAlign='left'
                                    validateStatus={error && error.npk ? 'error' : false}
                                    help={error && error.npk ? error.npk[0] : false}
                                >
                                    <Input value={data.npk} onChange={(e) => handleChange('npk', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Division"
                                    labelAlign='left'
                                    validateStatus={error && error.division ? 'error' : false}
                                    help={error && error.division ? error.division[0] : false}
                                >
                                    <Input value={data.division} onChange={(e) => handleChange('division', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Department"
                                    labelAlign='left'
                                    validateStatus={error && error.department ? 'error' : false}
                                    help={error && error.department ? error.department[0] : false}
                                >
                                    <Input value={data.department} onChange={(e) => handleChange('department', e.target.value)} />
                                </Form.Item>

                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>

                                <Form.Item
                                    required
                                    label="Position"
                                    labelAlign='left'
                                    validateStatus={error && error.position ? 'error' : false}
                                    help={error && error.position ? error.position[0] : false}
                                >
                                    <Input value={data.position} onChange={(e) => handleChange('position', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Email"
                                    labelAlign='left'
                                    validateStatus={error && error.email ? 'error' : false}
                                    help={error && error.email ? error.email[0] : false}
                                    rules={[{ required: true, type: 'email', message: 'not valid email format' }]}
                                >
                                    <Input value={data.email} onChange={(e) => handleChange('email', e.target.value)} />
                                </Form.Item>

                                <Form.Item
                                    required
                                    label="Whatsapp"
                                    labelAlign='left'
                                    validateStatus={error && error.phone ? 'error' : false}
                                    help={error && error.phone ? error.phone[0] : false}
                                >
                                    <PhoneInput
                                        className="ant-input"
                                        international
                                        withCountryCallingCode
                                        onChange={(e) => handlePhoneNumber(e)}
                                    />
                                </Form.Item>
                                {/* <Form.Item
                                        required
                                        label="Password"
                                        labelAlign='left'
                                        validateStatus={error && error.password ? 'error' : false }
                                        help={error && error.password ? error.password[0] : false }
                                    >
                                        <Input.Password value={data.password} onChange={(e) => handleChange('password', e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        label="Retype Password"
                                        labelAlign='left'
                                    >
                                        <Input.Password value={data.password_confirmation} onChange={(e) => handleChange('password_confirmation', e.target.value)} />
                                    </Form.Item> */}

                                <Form.Item
                                    label="Role"
                                    labelAlign='left'
                                >
                                    <Select
                                        labelInValue
                                        onChange={(value) => handleChangeSelect('role', value)}
                                        onFocus={() => getRole('')}
                                        showSearch
                                        onSearch={(value) => getRole(value)}
                                        filterOption={false}
                                        value={{ key: data.role_id, label: data.role_name }}
                                    >
                                        {
                                            data.roles && data.roles.map(role => {
                                                return <Option value={role._id} key={role._id}>{role.name}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="photo"
                                    label="Photo"
                                    labelAlign='left'
                                    extra='Besaran file maksimal 2MB'
                                >
                                    <Fragment>
                                        <Upload onRemove={handleRemove} beforeUpload={handleBeforeUpload} fileList={[]}>
                                            <Button>
                                                Upload <UploadOutlined />
                                            </Button>
                                        </Upload>
                                        {
                                            data.photo && <List>
                                                <List.Item actions={[<Button onClick={handleRemove} type="danger" ghost><DeleteOutlined /></Button>]}>
                                                    <List.Item.Meta description={data.photo.name} />
                                                </List.Item>
                                            </List>
                                        }
                                    </Fragment>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row style={{ marginTop: 30 }}>
                            <Col lg={24} md={24} sm={24} xs={24} >
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </Content>
        </Fragment>
    )
}

export default CreateUser