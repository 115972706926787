import React, { useState, useEffect, Fragment } from 'react'
import QRCode from 'qrcode.react'

function QRCodeM(props) {

    const qrcode = props.match.params.qrcode

    console.log(qrcode);

    return (
        <div>
            <QRCode className="qrbody-qrcode" size={500} value={qrcode} />
        </div>
    )

}
export default QRCodeM