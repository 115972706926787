import React, { useState, useEffect, Fragment } from 'react'
import { Card, Typography, Menu, Dropdown, Layout, Row, Col, Breadcrumb, Button, Input, Table, Modal, message, Space, Avatar, DatePicker, Form, Select, Radio, TimePicker, Collapse, Upload, List } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global'
import { UserOutlined, MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons'
import './RoomReservation.less'
import { file } from '@babel/types';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'

const { Content } = Layout
const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { Panel } = Collapse;


const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 16,
        span: 0,
    },
}


function EditRoomReservation(props) {

    //#region State
    const [loading, setLoading] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [error, setError] = useState()
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'room_name',
        order: 'ascend'
    })
    const [priority, setPriority] = useState([])

    const [data, setData] = useState({
        room_id: '',
        room_name: '',
        room_ext: '',
        meeting_id: '',
        npk: '',
        name: '',
        division: '',
        department: '',
        kind_meeting: '',
        topic_meeting: '',
        date: moment(new Date(), 'YYYY-MM-DD'),
        start_date: moment(new Date(), 'YYYY-MM-DD'),
        start_time: '',
        end_date: moment(new Date(), 'YYYY-MM-DD'),
        end_time: '',
        type: '',
        priority_id: '',
        priority_name: '',
    })

    //state untuk menampung data user internal detail
    const [internal, setInternal] = useState({
        id: '',
        name: '',
        email: '',
        department: '',
        phone: '',
        type: 0
    })

    //state untuk menampung data user external detail
    const [external, setExternal] = useState({
        id: '',
        name: '',
        email: '',
        department: '',
        phone: '',
        type: 1
    })

    //menampung data dari option pantry di modal
    const [pantry, setPantry] = useState({
        id: '',
        name: '',
        qty: '',
        description: ''
    })

    const [newInternalPartisipant, setNewInternalPartisipant] = useState([
        { name: '', email: '', department: '', phone: '', type: 0 }
    ])
    const [newExternalPartisipant, setNewExternalPartisipant] = useState([
        { name: '', email: '', department: '', phone: '', type: 1 }
    ])

    //Show Modal State
    const [modalInvitation, setModalInvitation] = useState(false)
    const [modalPantry, setModalPantry] = useState(false)
    const [modalNewInternalPartisipant, setModalNewInternalPartisipant] = useState(false)
    const [modalNewExternalPartisipant, setmodalNewExternalPartisipant] = useState(false)

    // Radio in modal
    const [radioValue, setRadioValue] = useState('internal')

    //State in modal

    //array untuk select option
    const [internalArr, setInternalArr] = useState([])
    const [externalArr, setExternalArr] = useState([])
    const [pantryArr, setPantryArr] = useState([])

    //untuk menyimpan array object saat add data di dalam modal
    const [dataInternal, setDataInternal] = useState([])
    const [dataExternal, setDataExternal] = useState([])
    const [dataPantry, setDataPantry] = useState([])

    //state array yg di tampilkan di table
    const [dataMergePantry, setDataMergePantry] = useState([])
    const [dataMergeInvitation, setDataMergeInvitation] = useState([])

    // State Hours
    // const [bookingTime, SetBookingTime] = useState([])
    const [sBookingTime, SetSBookingTime] = useState([])
    const [eBookingTime, SetEBookingTime] = useState([])

    //#endregion

    //#region get Data
    useEffect(() => {
        const data = getDataSession()
        getDetailMeeting(data)
    }, [])

    const getDataSession = () => {
        let dataUser = localStorage.getItem('user_data')
        dataUser = JSON.parse(dataUser)
        return {
            ...data,
            npk: dataUser.npk,
            name: dataUser.name,
            division: dataUser.division,
            department: dataUser.department
        }
    }

    const getDetailMeeting = (data) => {
        Axios.get(`${url}/meeting-list/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log('ini meeting detail nya', res.data.data)
            setData({
                ...data,
                meeting_id: res.data.data._id,
                room_id: res.data.data.room_id,
                room_name: res.data.data.room_name,
                room_ext: res.data.data.room_ext,
                kind_meeting: res.data.data.kind_meeting,
                topic_meeting: res.data.data.topic_meeting,
                date: res.data.data.date,
                start_date: moment(res.data.data.start_time).format('YYYY-MM-DD'),
                start_time: res.data.data.start_time,
                end_date: moment(res.data.data.end_time).format('YYYY-MM-DD'),
                end_time: res.data.data.end_time,
                type: res.data.data.type,
                priority_id: res.data.data.priority_id,
                priority_name: res.data.data.priority_name,

            })
            res.data.data.invitation.length > 0 ? setDataMergeInvitation(res.data.data.invitation) : setDataMergeInvitation([])
            res.data.data.pantries != null ? setDataMergePantry(res.data.data.pantries) : setDataMergePantry([])
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }


    const getPriority = () => {
        setLoading(true)
        Axios.get(`${url}/priority-with-detail`, {
            params: {
                variable: "parent_level_priority",
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then(res => {
            let data = []
            res.data.map((v, i, r) => {

                let roles = []

                v.data.map((val, ind, rec) => {

                    roles.push({
                        id: val._id,
                        name: val.name,
                    })

                })

                let Obj = {
                    id: v.id,
                    name: v.name,
                    roles: roles
                }

                data.push(Obj)

            })
            setPriority(data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {

            setLoading(false)

        })
    }

    //get data untuk di dalam modal
    const getUsersInternal = (keyword, page) => {

        Axios.get(`${url}/externalparticipants`, {
            params: {
                keyword,
                columns: [
                    'name',
                    'email',
                    'company',
                    'phone',
                    'address',
                    'department'
                ],
                perpage: 10,
                page,
                order: 'ascend',
                sort: 'name',
                type: 0
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setInternalArr(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    const getUserInternalDetail = (id) => {

        Axios.get(`${url}/externalparticipants/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setInternal({
                ...internal,
                id: res.data.data._id,
                name: res.data.data.name,
                email: res.data.data.email,
                phone: res.data.data.phone,
                department: res.data.data.department
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    //get data untuk di dalam modal
    const getUsersExternal = (keyword, page) => {

        Axios.get(`${url}/externalparticipants`, {
            params: {
                keyword,
                columns: [
                    'name',
                    'email',
                    'company',
                    'phone',
                    'address',
                    'department'
                ],
                perpage: 10,
                page,
                order: 'ascend',
                sort: 'name',
                type: 1
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setExternalArr(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    const getUserExternalDetail = (id) => {

        Axios.get(`${url}/externalparticipants/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setExternal({
                ...external,
                id: res.data.data._id,
                name: res.data.data.name,
                email: res.data.data.email,
                phone: res.data.data.phone,
                department: res.data.data.department
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    //get data untuk di dalam modal
    const getPantry = (keyword, page) => {

        Axios.get(`${url}/pantry`, {
            params: {
                keyword,
                columns: [
                    'name',
                ],
                perpage: 10,
                page,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setPantryArr(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }
    //#endregion

    //#region Action
    const onSaveNewInternalPartisipant = () => {
        setLoading(true)

        if (newInternalPartisipant.length == 0 || newInternalPartisipant[0].phone == '') {
            setLoading(false)
            message.error('Form must be filled')

        } else {

            Axios.post(`${url}/meeting-list/register-externalparticipants`, newInternalPartisipant, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {

                message.success(res.data.message)

                setDataInternal([...dataInternal, ...res.data.data])
                setModalNewInternalPartisipant(!modalNewInternalPartisipant)
                setNewInternalPartisipant([
                    { name: '', email: '', department: '', phone: '', type: 0 }
                ])
                setLoading(false)
            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                    if (err.response.status === 422) {
                        setError(err.response.data.errors)
                    }
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
                setLoading(false)
            })

        }

    }

    const onSaveNewExternalPartisipant = () => {

        setLoading(true)


        if (newExternalPartisipant.length == 0 || newExternalPartisipant[0].phone == '') {
            setLoading(false)
            message.error('Form must be filled')

        } else {

            Axios.post(`${url}/meeting-list/register-externalparticipants`, newExternalPartisipant, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {

                console.log(JSON.stringify(res, null, 4))

                message.success(res.data.message)

                setDataExternal([...dataExternal, ...res.data.data])
                setmodalNewExternalPartisipant(!modalNewExternalPartisipant)
                setNewExternalPartisipant([
                    { name: '', email: '', department: '', phone: '', type: 1 }
                ])
                setLoading(false)
            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                    if (err.response.status === 422) {
                        setError(err.response.data.errors)
                    }
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
                setLoading(false)
            })

        }

    }
    //#endregion

    //#region Handle
    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    //handle untuk di dalam modal

    const handleRadioValue = (e) => {
        setRadioValue(e.target.value)
    }

    const handleInputPantry = (idx, param, e) => {
        let values = [...dataPantry]
        values[idx][param] = e.target.value
        setDataPantry(values)
    }

    const handleInputNewInternalPartisipant = (idx, param, e) => {
        let values = [...newInternalPartisipant]
        values[idx][param] = e.target.value
        setNewInternalPartisipant(values)
    }

    const handleInputNumberNewInternalPartisipant = (idx, e) => {

        setError(null)

        if (e && isValidPhoneNumber(e)) {
            let values = [...newInternalPartisipant]
            values[idx].phone = e
            setNewInternalPartisipant(values)

        } else {
            setError({
                phone: [
                    'Invalid phone number'
                ]
            })
        }
    }

    const handleInputNewExternalPartisipant = (idx, param, e) => {
        let values = [...newExternalPartisipant]
        values[idx][param] = e.target.value
        setNewExternalPartisipant(values)
    }

    const handleInputNumberNewExternalPartisipant = (idx, e) => {

        setError(null)

        if (e && isValidPhoneNumber(e)) {
            let values = [...newExternalPartisipant]
            values[idx].phone = e
            setNewExternalPartisipant(values)

        } else {
            setError({
                phone: [
                    'Invalid phone number'
                ]
            })
        }
    }

    const resetData = () => {
        setData({
            ...data,
            kind_meeting: '',
            topic_meeting: '',
            start_time: '',
            end_time: '',
            start_hour: '',
            end_hour: '',
            type: '',
            priority_id: '',
            priority_name: '',
        })
    }


    const handleResetBooking = () => {
        resetData()
        setDataMergeInvitation([])
        setDataMergePantry([])
    }

    const handleSaveBooking = () => {

        let dataBook = {
            room_id: data.room_id,
            room_name: data.room_name,
            room_ext: data.room_ext,
            npk: data.npk,
            name: data.name,
            division: data.division,
            department: data.department,
            type: data.type,
            topic_meeting: data.topic_meeting,
            kind_meeting: data.kind_meeting,
            priority_id: data.priority_id,
            priority_name: data.priority_name,
            date: moment(data.date).format('YYYY-MM-DD'),
            start_time: data.start_time,
            end_time: data.end_time,
            pantries: dataMergePantry.length > 0 ? dataMergePantry : [],
            invitation: dataMergeInvitation.length > 0 ? dataMergeInvitation : null

        }

        setLoadingBtn(true)

        Axios.post(`${url}/meeting-list/${data.meeting_id}`, dataBook, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                message.success(res.data.message)
                props.history.push(`/room-reservation/list/${data.room_id}`)
            })
            .catch((err) => {

                if (err.response) {

                    if (typeof err.response.data.message === 'string' && err.response.data.message !== null) {

                        message.error(err.response.data.message)

                    } else {

                        setError(err.response.data.message)
                        message.error('Required field can\'t empty.')
                        window.scrollTo(0, 0)

                    }


                    if (err.response.status === 422) {
                        setError(err.response.data.errors)
                    }

                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
            }).finally(() => {
                setLoadingBtn(false)
                setLoading(false)
            })
    }
    //#endregion

    //#region Form Data
    useEffect(() => {
        if (data.type == 'priority') {
            getPriority()
        }
    }, [data.type])


    const getStartTime = () => {
        setLoading(true)
        setData({
            ...data,
            end_time: ''
        })
        Axios.get(`${url}/meeting-list/sroomtime/${props.match.params.id}`, {
            params: {
                id_room: data.room_id,
                type: data.type,
                priority_id: data.type == 'priority' ? data.priority_id : '',
                priority_name: data.type == 'priority' ? data.priority_name : '',
                date: moment(data.date).format('YYYY-MM-DD')
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            SetSBookingTime(res.data.data)

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(
            setLoading(false)
        )

    }


    const getEndTime = () => {
        setLoading(true)
        setData({
            ...data,
            end_time: ''
        })
        Axios.get(`${url}/meeting-list/eroomtime/${props.match.params.id}`, {
            params: {
                id_room: data.room_id,
                type: data.type,
                priority_id: data.type === 'priority' ? data.priority_id : '',
                priority_name: data.type === 'priority' ? data.priority_name : '',
                date: moment(data.date).format('YYYY-MM-DD'),
                start_date: moment(data.start_date).format('YYYY-MM-DD'),
                start_time: data.start_time,
                end_date: moment(data.end_date).format('YYYY-MM-DD'),
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            SetEBookingTime(res.data.data)

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(
            setLoading(false)
        )

    }
    //#endregion

    const columnsInvitation = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: false
        },
        {
            title: 'Department',
            dataIndex: 'department',
            sorter: false
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            sorter: false,
        },
        {
            title: '',
            align: 'right',
            sorter: false,
            render: (text, row, index) => {
                return (
                    <Fragment>
                        <Button
                            type='primary'
                            onClick={() => {
                                let newValue = [...dataMergeInvitation]
                                newValue.splice(index, 1)
                                setDataMergeInvitation(newValue)
                            }}
                        >Delete</Button>
                    </Fragment>
                )
            }
        }
    ]

    const columnsFood = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            sorter: false
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: false
        },
        {
            title: '',
            align: 'right',
            sorter: false,
            render: (text, row, index) => {
                return (
                    <Fragment>
                        <Button
                            type='primary'
                            onClick={() => {
                                let newValue = [...dataMergePantry]
                                newValue.splice(index, 1)
                                setDataMergePantry(newValue)
                            }}
                        >Delete</Button>
                    </Fragment>
                )
            }
        }
    ]

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    console.log('data', data)

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title={`Edit Meeting ${data && data.topic_meeting}`} extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={`/room-reservation/list/${data && data.room_id}`}>Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{`Edit Meeting ${data && data.topic_meeting}`}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Card className="body-data">
                    <div className='wrapper-body-data'>
                        <Form
                            {...layout}
                            name='reservation'
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                        >
                            <Row gutter={[15, 0]}>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label='NPK'
                                        name='npk'
                                        labelAlign='left'
                                        rules={[{ required: true, message: 'Please input NPK !' }]}

                                    >
                                        <div>
                                            <Input
                                                className='input'
                                                value={data.npk}
                                                disabled
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label='Nama'
                                        name='nama'
                                        labelAlign='left'
                                        rules={[{ required: true, message: 'Please input Nama !' }]}
                                    >
                                        <div>
                                            <Input
                                                className='input'
                                                value={data.name}
                                                disabled
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label='Divisi'
                                        name='divisi'
                                        labelAlign='left'
                                        rules={[{ required: true, message: 'Please input Divisi !' }]}
                                    >
                                        <div>
                                            <Input
                                                className='input'
                                                value={data.division}
                                                disabled
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label='Department'
                                        name='department'
                                        labelAlign='left'
                                        rules={[{ required: true, message: 'Please input Department !' }]}
                                    >
                                        <div>
                                            <Input
                                                className='input'
                                                value={data.department}
                                                disabled
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label='Jenis Meeting'
                                        name='jenis_meeting'
                                        labelAlign='left'
                                        validateStatus={error && error.kind_meeting ? 'error' : false}
                                        help={error && error.kind_meeting ? error.kind_meeting[0] : false}
                                        required={true}
                                    >
                                        <div>
                                            <Input
                                                className='input'
                                                value={data.kind_meeting}
                                                onChange={(e) => handleChange('kind_meeting', e.target.value)}
                                            />
                                        </div>
                                    </Form.Item>

                                </Col>

                                <Col lg={12} md={12} sm={24} xs={24} >

                                    <Form.Item
                                        label='Type'
                                        name='type'
                                        labelAlign='left'
                                        validateStatus={error && error.type ? 'error' : false}
                                        help={error && error.type ? error.type[0] : false}
                                        required={true}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <Button
                                                type={data.type == 'priority' ? 'primary' : ''}
                                                style={{ marginRight: 15 }}
                                                onClick={() => {
                                                    setData({
                                                        ...data,
                                                        type: 'priority',
                                                        start_time: '',
                                                        end_time: ''
                                                    })
                                                    message.info('Type Priority')
                                                }}
                                                block
                                            >
                                                Priority
                                            </Button>
                                            <Button
                                                type={data.type == 'normal' ? 'primary' : ''}
                                                onClick={() => {
                                                    setData({
                                                        ...data,
                                                        type: 'normal',
                                                        priority_id: '',
                                                        priority_name: '',
                                                        start_time: '',
                                                        end_time: ''
                                                    })
                                                    message.info('Priority Normal')
                                                }}
                                                block
                                            >
                                                Normal
                                            </Button>

                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label='Priority'
                                        name='type'
                                        labelAlign='left'
                                        required={data.type === 'normal' ? false : true}
                                    >
                                        <Select
                                            labelInValue
                                            onChange={(value) => {
                                                setData({
                                                    ...data,
                                                    priority_id: value.value,
                                                    priority_name: value.label
                                                })
                                            }}
                                            showSearch
                                            onSearch={(value) => getPriority(value)}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={data.priority_id}
                                            defaultValue={data.priority_id}
                                            disabled={data.type === 'normal' ? true : data.type === 'priority' ? false : true}
                                            loading={loading}
                                        >
                                            {
                                                priority && priority.map(level => {
                                                    return (
                                                        <OptGroup label={level.name} key={`${level.id}`}>
                                                            {
                                                                level.roles && level.roles.map(role => {
                                                                    return <Option value={`${level.id}_${role.id}`} key={`${level.id}_${role.id}`}>{role.name}</Option>
                                                                })
                                                            }
                                                        </OptGroup>
                                                    )
                                                })
                                            }


                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label='Start Time'
                                        name='booking_time'
                                        labelAlign='left'
                                        validateStatus={error && error.start_time ? 'error' : false}
                                        help={error && error.start_time ? error.start_time[0] : false}
                                        required={true}
                                    >
                                        <Row gutter={[15, 0]}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <DatePicker
                                                    allowClear={false}
                                                    format="YYYY-MM-DD"
                                                    onChange={(value) =>
                                                        setData({
                                                            ...data,
                                                            date: moment(value).format('YYYY-MM-DD'),
                                                            start_date: moment(value).format('YYYY-MM-DD'),
                                                            end_date: moment(data.end_date).format('YYYY-MM-DD') < moment(value).format('YYYY-MM-DD') ? moment(value).format('YYYY-MM-DD') : moment(data.end_date).format('YYYY-MM-DD'),
                                                            start_time: ''
                                                        })
                                                    }
                                                    value={moment(data.date, 'YYYY-MM-DD')}
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY-MM-DD");
                                                        return current && current < moment(customDate, "YYYY-MM-DD");
                                                    }}
                                                    disabled={data.type === 'normal' ? false : data.priority_id !== '' ? false : true}
                                                />
                                            </Col>

                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Select
                                                    placeholder="Start time"
                                                    showSearch
                                                    disabled={data.type === 'normal' ? false : data.priority_id !== '' ? false : true}
                                                    onChange={(value) => {
                                                        setData({
                                                            ...data,
                                                            start_time: value,
                                                        })
                                                    }}
                                                    onFocus={() => getStartTime()}
                                                    loading={loading}
                                                    value={data.start_time ? moment(data.start_time).format('HH:mm') : ''}
                                                >
                                                    {
                                                        sBookingTime && sBookingTime.map(Time => {
                                                            return <Option value={Time} key={Time}>{moment(Time).format('HH:mm')}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item
                                        label='End Time'
                                        name='booking_time'
                                        labelAlign='left'
                                        validateStatus={error && error.end_time ? 'error' : false}
                                        help={error && error.end_time ? error.end_time[0] : false}
                                        required={true}
                                    >
                                        <Row gutter={[15, 0]}>

                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <DatePicker
                                                    allowClear={false}
                                                    format="YYYY-MM-DD"
                                                    onChange={(value) =>
                                                        setData({
                                                            ...data,
                                                            end_date: moment(value).format('YYYY-MM-DD'),
                                                            end_time: ''
                                                        })
                                                    }
                                                    value={moment(data.end_date, 'YYYY-MM-DD')}
                                                    disabledDate={(current) => {
                                                        let customDate = moment(data.start_date).format("YYYY-MM-DD");
                                                        return current && current < moment(customDate, "YYYY-MM-DD");
                                                    }}
                                                    disabled={data.start_time !== '' ? false : true}
                                                />
                                            </Col>

                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Select
                                                    placeholder="End time"
                                                    showSearch
                                                    disabled={data.start_time !== '' ? false : true}
                                                    onChange={(value) => {
                                                        setData({
                                                            ...data,
                                                            end_time: value
                                                        })
                                                    }}
                                                    onFocus={() => getEndTime()}
                                                    loading={loading}
                                                    value={data.end_time ? moment(data.end_time).format('HH:mm') : ''}
                                                >
                                                    {
                                                        eBookingTime && eBookingTime.map(Time => {
                                                            return <Option value={Time} key={Time}>{moment(Time).format('HH:mm')}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item
                                        label='Topik Meeting'
                                        name='topik'
                                        labelAlign='left'
                                        validateStatus={error && error.topic_meeting ? 'error' : false}
                                        help={error && error.topic_meeting ? error.topic_meeting[0] : false}
                                        required={true}
                                    >
                                        <div>
                                            <TextArea
                                                rows={3}
                                                className='input'
                                                value={data.topic_meeting}
                                                onChange={(e) => handleChange('topic_meeting', e.target.value)}
                                                style={{ height: '86px' }}
                                            />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <Collapse accordion defaultActiveKey={['1']}>
                        <Panel header='Invitation' key='1'>
                            <Row style={{ marginBottom: 30, marginTop: 10 }}>
                                <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button type='primary' onClick={() => setModalInvitation(!modalInvitation)}><PlusOutlined />Add Participant</Button>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={24} md={24} sm={24} xs={24} style={{ overflowX: 'auto' }}>
                                    <div>
                                        <Table
                                            columns={columnsInvitation}
                                            dataSource={dataMergeInvitation}
                                            rowKey={(data) => data.id}
                                            onChange={handleTableChange}
                                            pagination={{ pageSize: 10, current: page, showSizeChanger: false }}
                                            loading={loading}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>

                    <Collapse accordion defaultActiveKey={['2']}>
                        <Panel header='Welcome drink' key='2'>
                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button type='primary' onClick={() => setModalPantry(!modalPantry)}><PlusOutlined />Add Drinks</Button>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={24} md={24} sm={24} xs={24} style={{ overflowX: 'auto' }}>
                                    <div>
                                        <Table
                                            columns={columnsFood}
                                            dataSource={dataMergePantry}
                                            rowKey={(data) => data.id}
                                            onChange={handleTableChange}
                                            pagination={{ pageSize: 10, current: page, showSizeChanger: false }}
                                            loading={loading}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>

                    <div className='wrapperBtn'>
                        <Button loading={loadingBtn} type="primary" onClick={handleSaveBooking} className='mr-button'>
                            Submit
                        </Button>
                        <Button htmlType="reset" onClick={handleResetBooking}>
                            Reset
                        </Button>
                    </div>

                </Card>
                <Modal
                    title='Add Participant'
                    centered
                    visible={modalInvitation}
                    onOk={() => {
                        if (dataInternal.length == 0 && dataExternal.length == 0) {
                            setModalInvitation(!modalInvitation)
                        } else {
                            let xxx = dataMergeInvitation.concat(dataInternal, dataExternal)
                            if (Array.isArray(xxx[0])) {
                                xxx.shift()
                            }
                            setDataMergeInvitation(xxx)
                            setModalInvitation(!modalInvitation)
                            setDataInternal([])
                            setDataExternal([])

                        }
                    }}
                    onCancel={() => setModalInvitation(!modalInvitation)}
                    width={1000}
                >
                    <Form
                        layout='vertical'
                    >
                        <Form.Item>
                            <Radio.Group onChange={handleRadioValue} value={radioValue}>
                                <Radio value='internal'>Internal</Radio>
                                <Radio value='external'>External</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {
                            radioValue === 'internal' ?
                                <>
                                    <Form.Item>
                                        <div className='search-modal'>
                                            <div style={{ marginRight: 20 }}>Search</div>
                                            <Select
                                                placeholder="Type to search ..."
                                                style={{ width: '100%', marginRight: 20 }}
                                                className='input'
                                                onChange={(value) => getUserInternalDetail(value)}
                                                onFocus={() => getUsersInternal('')}
                                                showSearch
                                                onSearch={(value) => getUsersInternal(value)}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={internal.id}
                                            >
                                                {
                                                    internalArr && internalArr.map(role => {
                                                        return <Option value={role._id} key={role._id}>{role.name}</Option>
                                                    })
                                                }

                                            </Select>
                                            <Button
                                                type='primary'
                                                style={{ marginRight: 10 }}
                                                onClick={() => setDataInternal([...dataInternal, internal])}
                                                disabled={!internal.id}
                                            >Add</Button>
                                            <Button
                                                type='primary'
                                                onClick={() => setModalNewInternalPartisipant(!modalNewInternalPartisipant)}
                                            >
                                                Add New Partisipant
                                            </Button>
                                        </div>
                                    </Form.Item>
                                    {
                                        dataInternal.map((res, index) => {
                                            return (
                                                <div className='input-field-modal' key={index}>
                                                    <Form.Item
                                                        label='Name'
                                                        name='name'
                                                        rules={[{ required: true, message: 'Please input name !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.name}
                                                                disabled={res.name}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='Email'
                                                        name='email'
                                                        rules={[{ required: true, message: 'Please input email!' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.email}
                                                                disabled={res.email}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='Department'
                                                        name='department'
                                                        rules={[{ required: true, message: 'Please input department !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.department}
                                                                disabled={res.department}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='Whatsapp'
                                                        name='phone'
                                                        rules={[{ required: true, message: 'Please input whatsapp phone !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.phone}
                                                                disabled={res.phone}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Button
                                                        type='primary'
                                                        onClick={() => {
                                                            let values = [...dataInternal]
                                                            values.splice(values.findIndex(idx => idx == index), 1)
                                                            setDataInternal(values)
                                                        }}>Delete</Button>
                                                </div>
                                            )
                                        })
                                    }
                                </> :
                                <>
                                    <Form.Item>
                                        <div className='search-modal'>
                                            <div style={{ marginRight: 20 }}>Search</div>
                                            <Select
                                                labelInValue
                                                style={{ width: '100%', marginRight: 20 }}
                                                className='input'
                                                onChange={(value) => getUserExternalDetail(value.value)}
                                                onFocus={() => getUsersExternal('')}
                                                showSearch
                                                onSearch={(value) => getUsersExternal(value)}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={{ key: external.id, label: external.name }}
                                            >
                                                {
                                                    externalArr && externalArr.map(role => {
                                                        return <Option value={role._id} key={role._id}>{role.name}</Option>
                                                    })
                                                }

                                            </Select>
                                            <Button
                                                type='primary'
                                                style={{ marginRight: 10 }}
                                                onClick={() => setDataExternal([...dataExternal, external])}
                                                disabled={!external.id}
                                            >Add</Button>
                                            <Button
                                                type='primary'
                                                onClick={() => setmodalNewExternalPartisipant(!modalNewInternalPartisipant)}
                                            >
                                                Add New Partisipant
                                            </Button>
                                        </div>
                                    </Form.Item>
                                    {
                                        dataExternal.map((res, index) => {
                                            return (
                                                <div className='input-field-modal' key={index}>
                                                    <Form.Item
                                                        label='Name'
                                                        name='name'
                                                        rules={[{ required: true, message: 'Please input name !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.name}
                                                                disabled={res.name}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='Email'
                                                        name='email'
                                                        rules={[{ required: true, message: 'Please input address !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.email}
                                                                disabled={res.email}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='Department'
                                                        name='department'
                                                        rules={[{ required: true, message: 'Please input department !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.department}
                                                                disabled={res.department}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label='Whatsapp'
                                                        name='phone'
                                                        rules={[{ required: true, message: 'Please input phone number !' }]}
                                                    >
                                                        <div>
                                                            <Input
                                                                className='input-modal'
                                                                value={res.phone}
                                                                disabled={res.phone}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    <Button
                                                        type='primary'
                                                        onClick={() => {
                                                            let values = [...dataExternal]
                                                            values.splice(values.findIndex(idx => idx == index), 1)
                                                            setDataExternal(values)
                                                        }}>Delete</Button>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                        }

                    </Form>
                </Modal>
                <Modal
                    title='Add Orders'
                    centered
                    visible={modalPantry}
                    onOk={() => {
                        if (dataPantry.length == 0) {
                            setModalPantry(!modalPantry)
                        } else {
                            if (dataMergePantry.length > 0) {
                                setDataMergePantry(dataMergePantry.concat(dataPantry))

                            } else {
                                setDataMergePantry(dataPantry)
                            }
                            setModalPantry(!modalPantry)
                            setDataPantry([])
                        }
                    }}
                    onCancel={() => setModalPantry(!modalPantry)}
                    width={700}
                >
                    <Form
                        layout='vertical'
                    >
                        <Form.Item>
                            <div className='search-modal'>
                                <div style={{ marginRight: 20 }}>Search</div>
                                <Select
                                    labelInValue
                                    style={{ width: '100%', marginRight: 20 }}
                                    className='input'
                                    onChange={(value) => {
                                        setPantry({
                                            ...pantry,
                                            id: value.value,
                                            name: value.label
                                        })
                                    }}
                                    onFocus={() => getPantry('')}
                                    showSearch
                                    onSearch={(value) => getPantry(value)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={{ key: pantry.id, label: pantry.name }}
                                >
                                    {
                                        pantryArr && pantryArr.map(role => {
                                            return <Option value={role._id} key={role._id}>{role.name}</Option>
                                        })
                                    }

                                </Select>
                                <Button
                                    type='primary'
                                    onClick={() => setDataPantry([...dataPantry, pantry])}
                                    disabled={!pantry.id}
                                >Add</Button>
                            </div>
                        </Form.Item>
                        {
                            dataPantry.map((res, index) => {
                                return (
                                    <div key={index} className='input-field-modal'>
                                        <Form.Item
                                            label='Name'
                                            name='name'
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={res.name}
                                                    disabled={res.name}
                                                    onChange={(e) => handleInputPantry(index, e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Qty'
                                            name='qty'
                                            rules={[{ required: true, message: 'Please input quantity' }]}
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputPantry(index, 'qty', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Description'
                                            name='description'
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputPantry(index, 'description', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                let values = [...dataPantry]
                                                values.splice(values.findIndex(idx => idx == index), 1)
                                                setDataPantry(values)
                                            }}>Delete</Button>
                                    </div>
                                )
                            })
                        }
                    </Form>
                </Modal>
                <Modal
                    title='New Internal Partisipant'
                    confirmLoading={loading}
                    centered
                    visible={modalNewInternalPartisipant}
                    onOk={() => {
                        onSaveNewInternalPartisipant()
                    }}
                    onCancel={() => setModalNewInternalPartisipant(!modalNewInternalPartisipant)}
                    width={1000}
                >
                    <Button
                        type='primary'
                        style={{ marginBottom: 30 }}
                        onClick={() => setNewInternalPartisipant([...newInternalPartisipant, { name: '', email: '', department: '', phone: '', type: '0' }])}
                    ><PlusOutlined />Add Row</Button>
                    <Form
                        layout='vertical'
                    >

                        {
                            newInternalPartisipant.map((res, index) => {
                                return (
                                    <div key={index} className='input-field-modal'>
                                        <Form.Item
                                            label='Name'
                                            name='name'
                                            rules={[{ required: true, message: 'Please input name' }]}
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputNewInternalPartisipant(index, 'name', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Email'
                                            name='email'
                                            rules={[{ required: true, message: 'Please input email' }]}
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputNewInternalPartisipant(index, 'email', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Department'
                                            name='department'
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputNewInternalPartisipant(index, 'department', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Whatsapp'
                                            name='nomor_wa'
                                            validateStatus={error && error.phone ? 'error' : false}
                                            help={error && error.phone ? error.phone[0] : false}
                                        >
                                            <div>
                                                <PhoneInput
                                                    className={['input-modal', 'ant-input'].join(' ')}
                                                    international
                                                    withCountryCallingCode
                                                    defaultValue={''}
                                                    value={data.phone}
                                                    onChange={(e) => handleInputNumberNewInternalPartisipant(index, e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                let values = [...newInternalPartisipant]
                                                values.splice(values.findIndex(idx => idx == index), 1)
                                                setNewInternalPartisipant(values)
                                            }}>Delete</Button>
                                    </div>
                                )
                            })
                        }
                    </Form>
                </Modal>
                <Modal
                    title='New External Partisipant'
                    centered
                    confirmLoading={loading}
                    visible={modalNewExternalPartisipant}
                    onOk={() => {
                        onSaveNewExternalPartisipant()
                    }}
                    onCancel={
                        () => setmodalNewExternalPartisipant(!modalNewExternalPartisipant)
                    }
                    width={1000}
                >
                    <Button
                        type='primary'
                        style={{ marginBottom: 30 }}
                        onClick={() => setNewExternalPartisipant([...newExternalPartisipant, { name: '', email: '', department: '', phone: '', type: '1' }])}
                    ><PlusOutlined />Add</Button>
                    <Form
                        layout='vertical'
                    >

                        {
                            newExternalPartisipant.map((res, index) => {
                                return (
                                    <div key={index} className='input-field-modal'>
                                        <Form.Item
                                            label='Name'
                                            name='name'
                                            rules={[{ required: true, message: 'Please input name' }]}
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputNewExternalPartisipant(index, 'name', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Email'
                                            name='email'
                                            rules={[{ required: true, message: 'Please input email' }]}
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputNewExternalPartisipant(index, 'email', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Department'
                                            name='department'
                                        >
                                            <div>
                                                <Input
                                                    className='input-modal'
                                                    defaultValue={''}
                                                    onChange={(e) => handleInputNewExternalPartisipant(index, 'department', e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            label='Whatsapp'
                                            name='nomor_wa'
                                            validateStatus={error && error.phone ? 'error' : false}
                                            help={error && error.phone ? error.phone[0] : false}
                                        >
                                            <div>
                                                <PhoneInput
                                                    className={['input-modal', 'ant-input'].join(' ')}
                                                    international
                                                    withCountryCallingCode
                                                    defaultValue={''}
                                                    value={data.phone}
                                                    onChange={(e) => handleInputNumberNewExternalPartisipant(index, e)}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                let values = [...newExternalPartisipant]
                                                values.splice(values.findIndex(idx => idx == index), 1)
                                                setNewExternalPartisipant(values)
                                            }}>Delete</Button>
                                    </div>
                                )
                            })
                        }
                    </Form>
                </Modal>
            </Content>
        </Fragment>
    )
}


export default EditRoomReservation
