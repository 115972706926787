import React, { useState } from "react";
import {
  Menu,
  Badge,
  Avatar,
  Space,
  Dropdown,
  Tabs,
  List,
  Button,
  Input,
} from "antd";
import {
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import "./Navbar.less";
import { Link } from "react-router-dom";
import moment from "moment";

const { TabPane } = Tabs;

const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
];

function Navbar(props) {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState(false);

  const handleNotification = () => {
    setVisible(!visible);
  };

  const handleSeeMore = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    // console.log('terclick')
    localStorage.removeItem("token");
    localStorage.removeItem("fullname");
    localStorage.removeItem("avatar");
    localStorage.removeItem("permissions");
    localStorage.removeItem("redirect");
    localStorage.removeItem("email");
    localStorage.removeItem("reset_token");
    localStorage.removeItem("user_data");

    return props.history.push("/login");
  };

  const handleToggleSearch = (value = false) => {
    // console.log(value)
    setSearch(value);
  };

  return (
    <div className="navbar">
      <div>
        {props.collapsed ? (
          <MenuUnfoldOutlined onClick={props.onToggleCollapsed} />
        ) : (
          <MenuFoldOutlined onClick={props.onToggleCollapsed} />
        )}
      </div>
      {/* <div>
                {moment(new Date()).format('DD MMMM YYYY')}                
            </div> */}
      <div>
        <Menu mode="horizontal" className="menu-header">
          <Menu.Item key="profile">
            <Dropdown
              overlay={
                <Menu>
                  {/* <Menu.Item key="1">
                                    <Link to={`/forgot-password/change/${localStorage.getItem('reset_token')}`}>Change Password</Link>
                                </Menu.Item> */}
                  <Menu.Item key="2" onClick={handleLogout}>
                    <LogoutOutlined />
                    Logout
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}>
                <Space>
                  {localStorage.getItem("avatar") ? (
                    <Avatar size="small" src={localStorage.getItem("avatar")} />
                  ) : (
                    <Avatar size="small" icon={<UserOutlined />} />
                  )}
                  {!props.screens.xs && (
                    <span> {localStorage.getItem("fullname")} </span>
                  )}
                </Space>
              </a>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
}

export default Navbar;
