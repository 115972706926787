import React, { Fragment, useState } from 'react'
import { Input, Tabs, Button, message, Form, Row, Col, Image, Upload, List, Layout, Card } from 'antd'
import './ForgotPassword.less'
import { UserOutlined, LockOutlined, RedoOutlined, CopyrightCircleOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import QrReader from 'react-qr-reader'
import Axios from 'axios'
import { url } from '../../config/global'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
}

function ForgotPassword(props) {
    
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)

    const handleReset = () => {
        setEmail('')
    }

    const handleSubmit = async () => {

        setLoading(true)

        await Axios.post(`${url}/forgot-password`, {
            email: email
        })
        .then((res) => {
            message.success(res.data.message)
            handleReset()
            setLoading(false)
        })
        .catch((err) => {
            setLoading(false)
            message.error(err.response.data.message)
            setLoading(false)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
        })
    }

    return (
        <div className='main'>
            <Row justify='center'>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <img    
                        src={require('../../assets/img/logo-login.png')}
                        alt='bg-login'
                        className='image-logo'
                    />
                </Col>
            </Row> 
            <Row justify='center'>
                <Col lg={6} md={10} sm={24} xs={24} >
                    <div className='signin-text'>Forgot Password</div>
                    <Form 
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            required
                            validateStatus={error && error.email ? 'error' : false }
                            help={error && error.email ? error.email[0] : false }
                            >
                                <Input 
                                    className="login-input"
                                    placeholder="Input your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                        </Form.Item>
                        <div className='spacing'/>
                        <Row justify='space-between'>
                            <Col lg={11} md={11} sm={11} xs={11}>
                                <Button htmlType='submit' loading={loading} type="primary" className='btn-submit' >
                                    Submit
                                </Button>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1} />
                            <Col lg={11} md={11} sm={11} xs={11}>
                                <Button htmlType="reset" className='btn-reset' onClick={handleReset}>
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default ForgotPassword
