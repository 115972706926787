import React, { useState, useEffect, Fragment } from 'react'
import { Card, Typography, Menu, Dropdown, Layout, Row, Col, Button, Input, Table, Modal, message, Upload, Select } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, InfoCircleOutlined, ImportOutlined, ExportOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import './ExternalParticipants.less'
import fileDownload from 'js-file-download';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { Option } = Select
const { Dragger } = Upload


function ExternalParticipants() {

	const [keyword, setKeyword] = useState('')
	const [type, setType] = useState('0')
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [sort, setSort] = useState({
		sort: 'name',
		order: 'ascend'
	})
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [buttonAccess, setButtonAccess] = useState(null);

	const handleSearch = (value) => {
		setKeyword(value)
		setPage(1)
	}

	useEffect(() => {
		getData(keyword, page, sort, type)
		const pathname = window.location.pathname.split("/");
		const currentUrl = pathname[1];

		const permissions =
			localStorage.getItem("permissions") &&
			JSON.parse(localStorage.getItem("permissions"));

		if (permissions) {
			const findPermission = permissions.find(
				(permission) => permission.url === currentUrl
			);
			const findChildPermission = permissions
				.map((child) => {
					return child.children.find(
						(grandChild) => grandChild.url === currentUrl
					);
				})
				.filter((grandChild) => grandChild)[0];

			if (findChildPermission && findChildPermission.children) {
				if (findChildPermission.children) {
					const findButtonAccessChildren = findChildPermission.children
						.filter((x) => x !== null)
						.filter((x) => x.permission_type === "button");
					if (findButtonAccessChildren) {
						setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
					}
				}
			}
		}

	}, [keyword, page, sort, type])

	const handleCloseModal = () => {
		getData()
		setIsModalVisible(false)
	}

	const responseUpload = (e) => {

		if (typeof e.file.response !== "undefined") {
			let res = e.file.response
			if (res.result) {
				message.success(res.message)
			} else {
				message.error(res.message)
			}
		}
	}

	const showModal = () => {
		setIsModalVisible(true);
	};

	const exportData = () => {
		setLoading(true)

		Axios.get(`${url}/externalparticipants/export`, {
			responseType: 'blob',
			headers: {
				"Content-Type": "application/vnd.ms-excel",
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			}
		})
			.then((res) => {
				message.success('File downloaded')
				fileDownload(res.data, "Export ExternalParticipants" + ".xlsx");
			}).catch(err => {
				if (err.response) {
					message.error(err.response.data.message)
					if (err.response.status == 401) {
						localStorage.removeItem('token')
					}
				}
			}).finally(() => {
				setLoading(false)
			})
	}

	const getData = () => {
		setLoading(true)
		Axios.get(`${url}/externalparticipants`, {
			params: {
				keyword,
				columns: [
					'name',
					'email',
					'company',
					'phone',
					'address',
					'department'
				],
				perpage: 10,
				page,
				sort: sort.sort,
				order: sort.order,
				type: type
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {
			setUsers(res.data)
		}).catch(err => {
			if (err.response) {
				message.error(err.response.data.message)
				if (err.response.status == 401) {
					localStorage.removeItem('token')
				}
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const handleDelete = (id) => {

		Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			title: 'Are you sure?',
			content: (
				<div>
					<p>Deleted data cannot be restored</p>
				</div>
			),
			okText: 'Delete',
			showCancel: true,
			onCancel: () => {
				return
			},
			cancelText: 'Cancel',
			confirmLoading: loading,
			onOk: async () => {
				confirmDelete(id)
			}
		})
	}

	const confirmDelete = (id) => {

		setLoading(true)
		Axios.delete(`${url}/externalparticipants/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {
			message.success(res.data.message)
			getData(keyword, page, sort, type)
		}).catch(err => {
			if (err.response) {
				message.error(err.response.data.message)
				if (err.response.status == 401) {
					localStorage.removeItem('token')
				}
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const handleTableChange = (pagination, filters, sorter) => {
		setPage(pagination.current)
		setSort({
			sort: sorter.field,
			order: sorter.order
		})
	}

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: true
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			sorter: false,
		},
		{
			title: type == 0 ? 'Department' : 'Company',
			dataIndex: type == 0 ? 'department' : 'company',
			sorter: true
		},
		{
			title: 'Address',
			dataIndex: 'address',
			sorter: false,
		},
		{
			title: '',
			dataIndex: '_id',
			align: 'right',
			sorter: false,
			width: 100,
			render: (id) => {

				return (
					<Fragment>
						<Dropdown className="pointer" overlay={
							<Menu>
								{buttonAccess &&
									buttonAccess.find((x) => x.url === "external-participants/view") && (
										<Menu.Item key="0">
											<Link to={`/external-participants/view/${id}`}><InfoCircleOutlined /> View</Link>
										</Menu.Item>
									)}
								{buttonAccess &&
									buttonAccess.find((x) => x.url === "external-participants/edit") && (
										<Menu.Item key="1">
											<Link to={`/external-participants/edit/${id}`}><EditOutlined /> Edit</Link>
										</Menu.Item>
									)}
								{buttonAccess &&
									buttonAccess.find((x) => x.url === "external-participants/delete") && (
										<Menu.Item key="3">
											<span onClick={() => handleDelete(id)}><DeleteOutlined />Delete</span>
										</Menu.Item>
									)}
							</Menu>
						} trigger={['click']}>
							<span className="pointer" onClick={e => e.preventDefault()}>
								<MoreOutlined />
							</span>
						</Dropdown>
					</Fragment>
				)
			}
		}
	]

	if (!localStorage.getItem('token')) {
		return <Redirect to="/login" />
	}

	// console.log(type)

	return (
		<Fragment>
			<Row>
				<Col xs={24}>
					<Card type="inner" title="External Participants" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
				</Col>
			</Row>

			<Content className="content">
				<Modal
					title="Upload Data"
					centered
					visible={isModalVisible}
					onOk={() => handleCloseModal()}
					onCancel={() => setIsModalVisible(false)}
				>
					<Button href="/files/ExternalParticipantsTemplate.xlsx" style={{ marginRight: 10 }}>Download Template</Button>
					<hr
						style={{
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: '#eee',
						}}
					/>
					<Dragger
						name='file'
						action={url + '/externalparticipants/import'}
						headers={{
							Authorization: `Bearer ${localStorage.getItem('token')}`
						}}
						onChange={(e) => responseUpload(e)}
					>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
					</Dragger>
				</Modal>
				<Card className="body-data">
					<div className='wrapperBtn'>
						<div className='left'>
							{buttonAccess &&
								buttonAccess.find((x) => x.url === "external-participants/create") && (
									<Link to="/external-participants/create">
										<Button type="primary" className='btn'><PlusOutlined />Add External Participants</Button>
									</Link>
								)}
							{buttonAccess &&
								buttonAccess.find((x) => x.url === "external-participants/import") && (
									<Button type="primary" className='btn' onClick={showModal}>
										<ImportOutlined />
										Import
									</Button>
								)}
							{buttonAccess &&
								buttonAccess.find((x) => x.url === "external-participants/export") && (
									<Button type="primary" className='btn' onClick={exportData}>
										<ExportOutlined />
										Export
									</Button>
								)}
						</div>
						<div
							style={{
								display: 'flex',
								marginLeft: 'auto',
								width: '40%'
							}}
						>
							<Select
								defaultValue="0"
								onChange={(e) => setType(e)}
								style={{
									marginRight: 10,
									width: '100%'
								}}
							>
								<Option value="0">Internal User</Option>
								<Option value="1">External User</Option>
							</Select>
							<Search
								placeholder="Search ..."
								// onSearch={(value) => handleSearch(value)}
								onChange={(e) => handleSearch(e.currentTarget.value)}
							/>
						</div>
					</div>
					<Row>
						<Col xs={24} style={{ overflowX: 'auto' }}>
							<Table
								columns={columns}
								dataSource={users.data}
								rowKey={(data) => data._id}
								onChange={handleTableChange}
								pagination={{ pageSize: 10, current: page, total: users.total, showSizeChanger: false }}
								loading={loading}
							/>
						</Col>
					</Row>

				</Card>
			</Content>
		</Fragment>
	)
}



export default ExternalParticipants
