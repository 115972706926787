import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message, InputNumber } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';
import moment from 'moment';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function EditMasterPantry(props) {

    const [data, setData] = useState({
        name: '',
        description: '',
        type: ''
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    // console.log(data)

    useEffect(() => {
        getPantry()
    }, [])

    const getPantry = () => {
        Axios.get(`${url}/pantry/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setData({
                ...data,
                name: res.data.data.name,
                description: res.data.data.description,
                type: res.data.data.type,
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }


    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const onSaveData = () => {

        setLoading(true)

        Axios.post(`${url}/pantry/${props.match.params.id}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/master-pantry')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            name: '',
            description: '',
            type: ''
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Pantry" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/master-pantry">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Pantry</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Name"
                                    validateStatus={error && error.name ? 'error' : false }
                                    help={error && error.name ? error.name[0] : false }
                                >
                                    <Input value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                 
                                    label="Description"
                                    validateStatus={error && error.description ? 'error' : false }
                                    help={error && error.description ? error.description[0] : false }
                                >
                                    <Input value={data.description} onChange={(e) => handleChange('description', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Type"
                                    validateStatus={error && error.type ? 'error' : false }
                                    help={error && error.type ? error.type[0] : false }
                                >
                                    <Select
                                        required
                                        labelInValue
                                        onChange={(value) => handleChange('type', value.value)}
                                        filterOption={false}
                                        value={{key: data.type, label: data.type}}
                                    >
                                       
                                        <Option value='makanan'>makanan</Option>
                                        <Option value='minuman'>minuman</Option>

                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
            </Content>
        </Fragment>
    )
}

export default EditMasterPantry