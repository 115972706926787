import React, { Fragment, useState } from 'react'
import { Input, Tabs, Button, message, Form, Row, Col, Upload, List, Layout } from 'antd'
import './Register.less'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import Axios from 'axios'
import { url } from '../../config/global'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'

const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
}

function Register(props) {
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState({
        username: '',
        full_name: '',
        npk: '',
        division: '',
        department: '',
        position: '',
        email: '',
        phone: '',
        photo: null

    })

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleRemove = () => {
        setData({
            ...data,
            photo: null
        })
    }

    const handleBeforeUpload = (files) => {

        setData({
            ...data,
            photo: files
        })

        return false
    }

    const handlePhoneNumber = (value) => {

        setError(null)

        if(value && isValidPhoneNumber(value)){

            setData({
                ...data,
                phone: value
            })

        } else {
            setError({
                phone: [
                    'Invalid phone number'
                ]
            })
        }
    }

    const handleReset = () => {
        setData({
            username: '',
            full_name: '',
            npk: '',
            division: '',
            department: '',
            position: '',
            email: '',
            phone: '',
            photo: null
        })
    }

    const handleLogin = async () => {

        setLoading(true)

        let fd = new FormData()

        fd.set('username', data.username)
        fd.set('npk', data.npk)
        fd.set('email', data.email)
        fd.set('phone', data.phone)
        fd.set('full_name', data.full_name)
        fd.set('division', data.division)
        fd.set('department', data.department)
        fd.set('position', data.position)
        if (data.photo) {
            fd.append('photo', data.photo)
        }

        Axios.post(`${url}/register`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            setLoading(false)
            // console.log(res)
            message.success(res.data.message)
            handleReset()
            props.history.push('/login')
        }).catch(err => {
            setLoading(false)
            // console.log(err.response)
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
            setLoading(false)
        })
    }

    return (
        <Fragment>
            <Content className="content">
                <Row justify='center'>
                    <Col>
                        <img    
                            src={require('../../assets/img/logo-login.png')}
                            alt='bg-login'
                            className='img-logo'
                        />
                    </Col>
                </Row>
                <Row type="flex" gutter={[10, 20]} justify="space-around">
                    <Col lg={12} sm={24} >
                        <div className='signin-text'>Register</div>
                        <Form 
                            name="control-hooks"
                            onFinish={handleLogin}
                        >
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                <Col lg={12} sm={24} >
                                    <Form.Item
                                        required
                                        validateStatus={error && error.username ? 'error' : false }
                                        help={error && error.username ? error.username[0] : false }
                                        >
                                        <Input
                                            className="login-input"
                                            placeholder="Username"
                                            onChange={(e) => handleChange('username', e.target.value)}
                                            value={data.username}
                                            autoFocus={true}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        validateStatus={error && error.full_name ? 'error' : false }
                                        help={error && error.full_name ? error.full_name[0] : false }
                                        >
                                            <Input 
                                                className="login-input"
                                                placeholder="Full Name"
                                                onChange={(e) => handleChange('full_name', e.target.value)}
                                                value={data.full_name}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        validateStatus={error && error.npk ? 'error' : false }
                                        help={error && error.npk ? error.npk[0] : false }
                                        >
                                            <Input 
                                                className="login-input"
                                                placeholder="NPK"
                                                onChange={(e) => handleChange('npk', e.target.value)}
                                                value={data.npk}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        validateStatus={error && error.division ? 'error' : false }
                                        help={error && error.division ? error.division[0] : false }
                                        >
                                            <Input 
                                                className="login-input"
                                                placeholder="Division"
                                                onChange={(e) => handleChange('division', e.target.value)}
                                                value={data.division}
                                            />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} sm={24} >
                                    <Form.Item
                                        required
                                        validateStatus={error && error.department ? 'error' : false }
                                        help={error && error.department ? error.department[0] : false }
                                        >
                                            <Input 
                                                className="login-input"
                                                placeholder="Department"
                                                onChange={(e) => handleChange('department', e.target.value)}
                                                value={data.department}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        validateStatus={error && error.position ? 'error' : false }
                                        help={error && error.position ? error.position[0] : false }
                                        >
                                            <Input 
                                                className="login-input"
                                                placeholder="Position"
                                                onChange={(e) => handleChange('position', e.target.value)}
                                                value={data.position}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        validateStatus={error && error.email ? 'error' : false }
                                        help={error && error.email ? error.email[0] : false }
                                        >
                                            <Input 
                                                className="login-input"
                                                placeholder="Email"
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                value={data.email}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        required
                                        validateStatus={error && error.phone ? 'error' : false }
                                        help={error && error.phone ? error.phone[0] : false }
                                        >
                                            <PhoneInput
                                                className="ant-input"
                                                international
                                                withCountryCallingCode
                                                value={data.phone}
                                                onChange={(e) => handlePhoneNumber(e)}
                                                placeholder="Whatsapp"
                                            />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                required
                                validateStatus={error && error.photo ? 'error' : false }
                                help={error && error.photo ? error.photo[0] : false }
                                extra='Besaran file maksimal 2MB'
                            >
                                <Fragment>
                                    <Upload onRemove={handleRemove} beforeUpload={handleBeforeUpload} fileList={[]}>
                                        <Button>
                                            Upload <UploadOutlined />
                                        </Button>
                                    </Upload>
                                    {
                                        data.photo && <List>
                                            <List.Item actions={[<Button onClick={handleRemove} type="danger" ghost><DeleteOutlined /></Button>]}>
                                                <List.Item.Meta description={data.photo.name} />
                                            </List.Item>
                                        </List>
                                    }
                                </Fragment>
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                    Submit
                                </Button>
                                <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form> 
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default Register
