import React, { Fragment, useContext, useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message, Popconfirm } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function EditPriority(props) {

    const [data, setData] = useState({
        name: '',
        priority_id: '',
        priority_level: ''
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [level, setLevel] = useState([])

    useEffect(() => {
        getDetail()
    }, [props.match.params.id])
    
    const getDetail =  () => {
        Axios.get(`${url}/priority/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
                // console.log(res)
                setData({
                    name: res.data.data.name,
                    priority_id: res.data.data.priority_id,
                    priority_level: res.data.data.priority_level
                })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    const getAllLevel = () => {
        Axios.get(`${url}/settings/find`, {
            params: {
                variable: "parent_level_priority",
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then(res => {
            // console.log(res)
            setLevel(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }


    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeSelect = (name, value) => {
        setError(null)
        setData({
            ...data,
            [`${name}_id`]: value.value,
            [`${name}_level`]: value.label
        })
    }

    const onSaveData = () => {
        // console.log(data)
        setLoading(true)

        const data_priority = {
            name: data.name,
            priority_id: data.priority_id,
            priority_level: data.priority_level
        }

        Axios.post(`${url}/priority/${props.match.params.id}`, data_priority, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/priority')
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            name: '',
            priority_id: '',
            priority_level: ''
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Priority" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/priority">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Add Priority</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Name Priority"
                                    validateStatus={error && error.name ? 'error' : false }
                                    help={error && error.name ? error.name[0] : false }
                                >
                                    <Input value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Priority"
                                    validateStatus={error && error.priority_id ? 'error' : false }
                                    help={error && error.priority_id ? error.priority_id[0] : false }
                                >
                                    <Select 
                                        labelInValue
                                        onChange={(value) => handleChangeSelect('priority', value)}
                                        onFocus={() => getAllLevel()}
                                        showSearch 
                                        onSearch={(value) => getAllLevel(value)} 
                                        filterOption={false}
                                        value={{ key: data.priority_id, label: data.priority_level }}
                                    >
                                        {
                                            level && level.map(role => {
                                                return <Option value={role.id} key={role.id}>{role.name}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                    Reset
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
            </Content>
        </Fragment>
    )
}

export default EditPriority