
let url
let urlAssets
let url_sso

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

    // url = "http://127.0.0.1:8000/api";
    // url_sso = "http://192.168.100.167:8000/api"
    
    url = 'https://api.meet.kelola.biz/api'
    url_sso = "https://api.sso.kelola.biz/api"


} else {

    url = 'https://api.doors.dharmap.com/api'
    url_sso = "https://api.doors.dharmap.com/api"

}

urlAssets = 'https://api.doors.dharmap.com/storage/'

export { url, urlAssets, url_sso }
