import React, { useState, useEffect, Fragment } from 'react';
import { Card, Typography, Menu, Dropdown, Layout, Row, Col, Breadcrumb, Button, Input, Table, Modal, message, Space, DatePicker } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global';
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './RoomReservation.less';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout


function ListRoomReservation(props) {

    const [keyword, setKeyword] = useState('')
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'start_time',
        order: 'ascend'
    })
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [detailRoom, setDetailRoom] = useState({})
    const [meetingList, setMeetingList] = useState([])
    const [userData, setUserData] = useState([])

    const [buttonAccess, setButtonAccess] = useState(null);

    const handleSearch = (value) => {
        setKeyword(value)
        setPage(1)
    }

    useEffect(() => {
        getDetailRoom()
        getMeetingList(keyword, page, sort, date)

        const pathname = window.location.pathname.split("/");
        const currentUrl = pathname[1];
        setUserData(JSON.parse(localStorage.getItem("user_data")));

        const permissions =
            localStorage.getItem("permissions") &&
            JSON.parse(localStorage.getItem("permissions"));

        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl
            );
            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren = findChildPermission.children
                        .filter((x) => x !== null)
                        .filter((x) => x.permission_type === "button");
                    if (findButtonAccessChildren) {
                        setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
                    }
                }
            }
        }
    }, [keyword, page, sort, date])

    console.log(userData.npk)
    const getDetailRoom = () => {
        setLoading(true)
        Axios.get(`${url}/room/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setDetailRoom(res.data.data)

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const getMeetingList = (keyword, page, sort, date) => {
        setLoading(true)
        Axios.get(`${url}/meeting-list/room/${props.match.params.id}`, {
            params: {
                keyword,
                columns: [
                    'departement',
                    'name'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                date: date
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log(res)
            setMeetingList(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = (id) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id)
            }
        })
    }

    const confirmDelete = (id) => {
        setLoading(true)
        Axios.delete(`${url}/meeting-list/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            getMeetingList(keyword, page, sort, date)
            message.success(res.data.message)

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const columns = [
        {
            title: 'Start Booking',
            dataIndex: 'start_time',
            sorter: false,
        },
        {
            title: 'End Booking',
            dataIndex: 'end_time',
            sorter: false,
        },
        {
            title: 'Topic',
            dataIndex: 'topic_meeting',
            sorter: false,
        },
        {
            title: 'PIC Name',
            dataIndex: 'name',
            sorter: false,
        },
        {
            title: 'Department',
            dataIndex: 'department',
            sorter: false,
        },
        {
            title: '',
            dataIndex: '_id',
            align: 'right',
            sorter: false,
            render: (id, data) => {
                const stampEnd = Math.floor(Date.parse(data.end_time) / 1000);
                const stampCur = Math.floor(Date.parse(new Date()) / 1000);
                const timeEnd = stampEnd - stampCur;
                const hourEnd = 2 * 3600;

                return (
                    <Fragment>
                        <Dropdown className="pointer" overlay={
                            <Menu>
                                {/* {buttonAccess && buttonAccess.find((x) => x.url === "room-reservation/edit") && (
                                    <Menu.Item key="1">
                                        <Link to={`/room-reservation/edit/${id}`}><EditOutlined /> Edit</Link>
                                    </Menu.Item>

                                )}
                                
                                {buttonAccess && buttonAccess.find((x) => x.url === "room-reservation/delete") && (
                                    <Menu.Item key="2">
                                        <span onClick={() => handleDelete(id)}><DeleteOutlined />Cancel</span>
                                    </Menu.Item>
                                )} */}
                                {/* {
                                    const stampEnd = Math.floor(Date.parse(timeEnd) / 1000);
                                    const stampCur = Math.floor(Date.parse(Curtime) / 1000);
                                } */}

                                {
                                    timeEnd >= hourEnd && userData.npk == data.created_by || userData.role_name === "Admin" ?
                                        (
                                            <Menu.Item key="1">
                                                <Link to={`/room-reservation/edit/${id}`}><EditOutlined /> Edit</Link>
                                            </Menu.Item>
                                        )
                                        : ""
                                }

                                {
                                    timeEnd >= hourEnd && userData.npk == data.created_by || userData.role_name === "Admin" ?
                                        (
                                            <Menu.Item key="2">
                                                <span onClick={() => handleDelete(id)}><DeleteOutlined />Cancel</span>
                                            </Menu.Item>
                                        )
                                        : ""
                                }

                                {/* {userData.npk == data.npk || userData.role_name === "Admin" && (
                                    <Menu.Item key="1">
                                        <Link to={`/room-reservation/edit/${id}`}><EditOutlined /> Edit</Link>
                                    </Menu.Item>

                                )} */}

                                {/* {userData.npk == data.npk || userData.role_name === "Admin" && (
                                    <Menu.Item key="2">
                                        <span onClick={() => handleDelete(id)}><DeleteOutlined />Cancel</span>
                                    </Menu.Item>
                                )} */}
                            </Menu>
                        } trigger={['click']}>
                            <span className="pointer" onClick={e => e.preventDefault()}>
                                <MoreOutlined />
                            </span>
                        </Dropdown>
                    </Fragment>
                )
            }
        }
    ]

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title={`Meeting List ${detailRoom && detailRoom.name}`} extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/room-reservation">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{`Meeting List ${detailRoom && detailRoom.name}`}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-around">

                                <Col xs={24} md={12} lg={14}>
                                    <Space>
                                        {buttonAccess &&
                                            buttonAccess.find((x) => x.url === "room-reservation/create") && (
                                                <Link to={`/room-reservation/create/${detailRoom && detailRoom._id}`}>
                                                    <Button type="primary"><PlusOutlined />Add</Button>
                                                </Link>
                                            )}
                                    </Space>
                                </Col>
                                <Col xs={24} md={12} lg={10} className="align-right">
                                    <DatePicker
                                        style={{ width: 250 }}
                                        defaultValue={moment(new Date())}
                                        onChange={(value) => setDate(moment(value).format('YYYY-MM-DD'))}
                                        format={'YYYY-MM-DD'}
                                    />
                                    <Search
                                        placeholder="Search ..."
                                        // onSearch={(value) => handleSearch(value)}
                                        onChange={(e) => handleSearch(e.currentTarget.value)}
                                        style={{ width: 250, marginLeft: 15 }}
                                    />
                                </Col>

                                <Col xs={24} style={{ overflowX: 'auto' }}>
                                    <Table
                                        columns={columns}
                                        dataSource={meetingList}
                                        rowKey={(data) => data._id}
                                        onChange={handleTableChange}
                                        pagination={{ pageSize: 10, current: page, total: meetingList.total, showSizeChanger: false }}
                                        loading={loading}
                                    />
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}



export default ListRoomReservation
