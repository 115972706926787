import React, { Fragment, useState } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout
const { TextArea } = Input

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function CreateMeetingRoom(props) {

    const [data, setData] = useState({
        name: '',
        description: '',
        photo: null
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleRemove = () => {
        setData({
            ...data,
            photo: null
        })
    }

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            photo: files
        })

        return false
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    const onSaveData = () => {
        // console.log(data)

        setLoading(true)

        let fd = new FormData()

        fd.set('name', data.name)
        fd.set('description', data.description)
        fd.set('ext', data.ext)
        if (data.photo) {
            fd.append('image_file', data.photo)
        }

        Axios.post(`${url}/room`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/meeting-room')
        }).catch(err => {
            // console.log(err.response)
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            name: '',
            description: '',
            photo: null,
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Meeting Room" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/meeting-room">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Add Meeting Room</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Card className="body-data">
                    <Form
                        {...layout}
                        name="control-hooks"
                        onFinish={onSaveData}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                    >
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item
                                    required
                                    label="Room Name"

                                    validateStatus={error && error.name ? 'error' : false}
                                    help={error && error.name ? error.name[0] : false}

                                >
                                    <Input value={data.name} onChange={(e) => handleChange('name', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Description"
                                    validateStatus={error && error.description ? 'error' : false}
                                    help={error && error.description ? error.description[0] : false}
                                >
                                    <TextArea value={data.description} onChange={(e) => handleChange('description', e.target.value)} rows={4} />
                                </Form.Item>
                                <Form.Item
                                    label="Ext"
                                    validateStatus={error && error.ext ? 'error' : false}
                                    help={error && error.ext ? error.ext[0] : false}
                                >
                                    <Input value={data.ext} onChange={(e) => handleChange('ext', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    name="photo"
                                    label="Photo"
                                    validateStatus={error && error.photo ? 'error' : false}
                                    help={error && error.photo ? error.photo[0] : false}
                                    extra="Besaran file maksimal 2MB"
                                >
                                    <Fragment>
                                        <Upload onRemove={handleRemove} beforeUpload={handleBeforeUpload} fileList={[]}>
                                            <Button>
                                                Upload <UploadOutlined />
                                            </Button>
                                        </Upload>
                                        {
                                            data.photo && <List>
                                                <List.Item actions={[<Button onClick={handleRemove} type="danger" ghost><DeleteOutlined /></Button>]}>
                                                    <List.Item.Meta description={data.photo.name} />
                                                </List.Item>
                                            </List>
                                        }
                                    </Fragment>
                                </Form.Item>


                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>

                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                        Reset
                                    </Button>
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
        </Fragment>
    )
}

export default CreateMeetingRoom