import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message, Avatar } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';
import { refreshStyles } from 'less';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout
const {TextArea} = Input

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function ViewUser(props) {

    const [data, setData] = useState({
        username: '',
        full_name: '',
        npk:'',
        division:'',
        department:'',
        position:'',
        email: '',
        phone: '',
        photo: null,
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        getPermission()
    }, [])

    const getPermission = () => {
        Axios.get(`${url}/user/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log('ini res nya',res)
            setData({
                ...data,
                photo: res.data.data.photo_url,
                username: res.data.data.username,
                full_name: res.data.data. full_name,
                npk: res.data.data.npk,
                email: res.data.data.email,
                phone: res.data.data.phone,
                division: res.data.data.division,
                department: res.data.data.department,
                position: res.data.data.position
            })
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    const dataview = [
        {
            title: ('Photo'),
            description: <Avatar src={data && data.photo}/>
        },
        {
            title: ('Username'),
            description: data && data.username
        },
        {
            title: ('Full Name'),
            description: data && data.full_name
        },
        {
            title: ('NPK'),
            description: data && data.npk
        },
        {
            title: ('Division'),
            description: data && data.division
        },
    ];

    const dataview2 = [
        {
            title: ('Department'),
            description: data && data.department
        },
        {
            title: ('Position'),
            description: data && data.position
        },
        {
            title: ('Email'),
            description: data && data.email
        },
        {
            title: ('Whatsapp'),
            description: data && data.phone
        },
    ];

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>View User</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/user">User</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>View User</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                                <Col xs={24} sm={24} md={12}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataview}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                title={<strong>{item.title}</strong>}
                                                description={item.description ? item.description : '-'}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataview2}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                title={<strong>{item.title}</strong>}
                                                description={item.description ? item.description : '-'}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default ViewUser