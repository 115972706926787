import React, { useState, useEffect, Fragment } from 'react'
import { Card, Typography, Menu, Dropdown, Layout, Row, Col, Button, Input, Table, Modal, message, Avatar, Upload, Select } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global'
import { UserOutlined, MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, InfoCircleOutlined, ImportOutlined, ExportOutlined } from '@ant-design/icons'
import './User.less'
import fileDownload from 'js-file-download';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { Dragger } = Upload


function User() {

	const [keyword, setKeyword] = useState('')
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [buttonAccess, setButtonAccess] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [error, setError] = useState(null);
	const [sort, setSort] = useState({
		sort: 'full_name',
		order: 'ascend'
	})

	const [roles, setRoles] = useState([])
	const [role, setRole] = useState({
		role_id: '',
		role_name: ''
	})

	const [data, setData] = useState({
		file: null,
	});

	const handleSearch = (value) => {
		setKeyword(value)
		setPage(1)
	}
	// console.log(users)

	useEffect(() => {
		getData(keyword, page, sort)
		getRole('')

		const pathname = window.location.pathname.split("/");
		const currentUrl = pathname[1];

		const permissions =
			localStorage.getItem("permissions") &&
			JSON.parse(localStorage.getItem("permissions"));

		if (permissions) {
			const findPermission = permissions.find(
				(permission) => permission.url === currentUrl
			);
			const findChildPermission = permissions
				.map((child) => {
					return child.children.find(
						(grandChild) => grandChild.url === currentUrl
					);
				})
				.filter((grandChild) => grandChild)[0];

			if (findChildPermission && findChildPermission.children) {
				if (findChildPermission.children) {
					const findButtonAccessChildren = findChildPermission.children
						.filter((x) => x !== null)
						.filter((x) => x.permission_type === "button");
					if (findButtonAccessChildren) {
						setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
					}
				}
			}
			// if (findPermission && findPermission.children) {
			//   const findButtonAccess = findPermission.children.filter(
			//     (x) => x.permission_type === "button"
			//   );
			//   if (findButtonAccess) {
			//     setButtonAccess(findButtonAccess);
			//   }
			// }
		}


	}, [keyword, page, sort])

	const handleRemove = () => {
		setData({
			...data,
			file: null,
		});
	};

	const responseUpload = (e) => {

		if (typeof e.file.response !== "undefined") {
			let res = e.file.response
			if (res.result) {
				message.success(res.message)
			} else {
				if (res.errors.role_id) {
					message.error('The role field is required.')
				} else {
					message.error(res.message)
				}
			}
		}
	}

	const showModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setData({
			...data,
			file: null,
		});
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onSaveData = () => {
		setLoading(true);

		let fd = new FormData();

		fd.append("file", data.file);

		Axios.post(`${url}/user/import`, fd, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		})
			.then((res) => {
				message.success(res.data.message);
				getData(keyword, page, sort);
				closeModal();
			})
			.catch((err) => {
				if (err.response) {
					message.error(err.response.data.message);
					if (err.response.status === 422) {
						setError(err.response.data.errors);
					}
					if (err.response.status === 401) {
						localStorage.removeItem("token");
					}
				}
				setLoading(false);
			});
	};

	const getData = () => {
		setLoading(true)
		Axios.get(`${url}/user`, {
			params: {
				keyword,
				columns: [
					'username',
					'full_name',
					'npk',
					'department',
					'email',
					'phone'
				],
				perpage: 10,
				page,
				sort: sort.sort,
				order: sort.order
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {
			// console.log(res)
			setUsers(res.data)
		}).catch(err => {
			if (err.response) {
				message.error(err.response.data.message)
				if (err.response.status == 401) {
					localStorage.removeItem('token')
				}
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const getRole = async (keyword) => {
		await Axios.get(`${url}/role/list`, {
			params: {
				keyword
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {
			setRoles(res.data.data)
		}).catch(err => {
			if (err.response) {
				message.error(err.response.data.message)
				if (err.response.status == 401) {
					localStorage.removeItem('token')
				}
			}
		})
	}

	const handleDelete = (id) => {

		Modal.confirm({
			title: 'Are you sure?',
			content: (
				<div>
					<p>Deleted data cannot be restored</p>
				</div>
			),
			okText: 'Delete',
			showCancel: true,
			onCancel: () => {
				return
			},
			cancelText: 'Cancel',
			confirmLoading: loading,
			onOk: async () => {
				confirmDelete(id)
			}
		})
	}

	const handleCloseModal = () => {
		getData()
		setIsModalVisible(false)
	}

	const confirmDelete = (id) => {

		setLoading(true)
		Axios.delete(`${url}/user/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {
			message.success(res.data.message)
			getData(keyword, page, sort)
		}).catch(err => {
			if (err.response) {
				message.error(err.response.data.message)
				if (err.response.status == 401) {
					localStorage.removeItem('token')
				}
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const exportData = () => {
		setLoading(true)

		Axios.get(`${url}/user/export`, {
			responseType: 'blob',
			headers: {
				"Content-Type": "application/vnd.ms-excel",
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			}
		})
			.then((res) => {
				message.success('File downloaded')
				fileDownload(res.data, "Export User" + ".xlsx");
			}).catch(err => {
				if (err.response) {
					message.error(err.response.data.message)
					if (err.response.status == 401) {
						localStorage.removeItem('token')
					}
				}
			}).finally(() => {
				setLoading(false)
			})
	}

	const handleTableChange = (pagination, filters, sorter) => {
		setPage(pagination.current)
		setSort({
			sort: sorter.field,
			order: sorter.order
		})
	}

	const columns = [
		{
			title: 'Photo',
			dataIndex: 'photo_url',
			sorter: false,
			render: (photo_url) => photo_url ? <Avatar src={photo_url} /> : <Avatar icon={<UserOutlined />} />,

		},
		{
			title: 'Username',
			dataIndex: 'username',
			sorter: true,

		},
		{
			title: 'Full Name',
			dataIndex: 'full_name',
			sorter: true,

		},
		{
			title: 'NPK',
			dataIndex: 'npk',
			sorter: true,

		},
		{
			title: 'Department',
			dataIndex: 'department',
			sorter: true,

		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true,

		},
		{
			title: 'Whatsapp Number',
			dataIndex: 'phone',
			sorter: true,

		},
		{
			title: '',
			dataIndex: '_id',
			align: 'right',
			sorter: false,
			width: 100,
			render: (id) => {

				return (
					<Fragment>
						<Dropdown className="pointer" overlay={
							<Menu>
								{buttonAccess &&
									buttonAccess.find((x) => x.url === "user/view") && (
										<Menu.Item key="0">
											<Link to={`/user/view/${id}`}><InfoCircleOutlined /> View</Link>
										</Menu.Item>
									)}
								{buttonAccess &&
									buttonAccess.find((x) => x.url === "user/edit") && (
										<Menu.Item key="1">
											<Link to={`/user/edit/${id}`}><EditOutlined /> Edit</Link>
										</Menu.Item>
									)}
								{buttonAccess &&
									buttonAccess.find((x) => x.url === "user/delete") && (
										<Menu.Item key="3">
											<span onClick={() => handleDelete(id)}><DeleteOutlined />Delete</span>
										</Menu.Item>
									)}
							</Menu>
						} trigger={['click']}>
							<span className="pointer" onClick={e => e.preventDefault()}>
								<MoreOutlined />
							</span>
						</Dropdown>
					</Fragment>
				)
			}
		}
	]

	if (!localStorage.getItem('token')) {
		return <Redirect to="/login" />
	}

	return (
		<Fragment>
			<Row >
				<Col xs={24}>
					<Card type="inner" title="User" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
				</Col>
			</Row>

			<Content className="content">
				<Modal
					title="Upload Data"
					centered
					visible={isModalVisible}
					onOk={() => handleCloseModal()}
					onCancel={() => setIsModalVisible(false)}
				>
					<div
						style={{
							display: 'flex'
						}}
					>
						<Button href="/files/UserTemplate.xlsx" style={{ marginRight: 10 }}>Download Template</Button>
						<Select
							labelInValue
							value={{ key: role.role_id, label: role.role_name }}
							onChange={(value) => setRole({ role_id: value.value, role_name: value.label })}
							onFocus={() => getRole('')}
							showSearch
							onSearch={(value) => getRole(value)}
							filterOption={false}
							style={{
								width: '100%'
							}}
						>
							{
								roles && roles.map(role => {
									return <Select.Option value={role._id} key={role._id}>{role.name}</Select.Option>
								})
							}

						</Select>
					</div>
					<hr
						style={{
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: '#eee',
						}}
					/>
					<Dragger
						name='file'
						action={url + '/user/import'}
						headers={{
							Authorization: `Bearer ${localStorage.getItem('token')}`
						}}
						data={{
							role_id: role.role_id,
							role_name: role.role_name
						}}
						onChange={(e) => responseUpload(e)}
					>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
					</Dragger>
				</Modal>
				<Card className="body-data">
					<div className='wrapperBtn'>
						<div className='left'>
							{buttonAccess &&
								buttonAccess.find((x) => x.url === "user/create") && (
									<Link to="/user/create">
										<Button type="primary" className='btn'><PlusOutlined />Add User</Button>
									</Link>
								)}
							{buttonAccess &&
								buttonAccess.find((x) => x.url === "user/import") && (
									<Button type="primary" className='btn' onClick={showModal} >
										<ImportOutlined />
										Import
									</Button>

								)}
							{buttonAccess &&
								buttonAccess.find((x) => x.url === "user/export") && (
									<Button type="primary" className='btn' onClick={exportData}>
										<ExportOutlined />
										Export
									</Button>
								)}
						</div>
						<div className='right'>
							<Search
								placeholder="Search ..."
								// onSearch={(value) => handleSearch(value)}
								onChange={(e) => handleSearch(e.currentTarget.value)}

							/>
						</div>
					</div>
					<Row>
						<Col xs={24} style={{ overflowX: 'auto' }}>
							<Table
								columns={columns}
								dataSource={users.data}
								rowKey={(data) => data._id}
								onChange={handleTableChange}
								pagination={{ pageSize: 10, current: page, total: users.total, showSizeChanger: false }}
								loading={loading}
								size='middle'
							/>
						</Col>
					</Row>
				</Card>
			</Content>
		</Fragment>
	)
}



export default User
