import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message, Table } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';
import moment from 'moment';
import 'moment/locale/id'


const { Title } = Typography
const { Option } = Select
const { Content } = Layout
const {TextArea} = Input

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function ViewRoomReservation(props) {

    const [data, setData] = useState({
        room_id: '',
        room_name: '',
        npk: '',
        name: '',
        division: '',
        department: '',
        start_date: '',
        end_date: '',
        kind_meeting: '',
        topic_meeting: '',
        type: '',
        priority_id: '',
        priority_name: '',
        vendors: [],
        pantries: []

    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    // console.log('ini pantries', data.pantries)
    
    useEffect(() => {
        getDetailMeeting()
    }, [])

    const getDetailMeeting = () => {
        
        setLoading(true)

        Axios.get(`${url}/meeting-list/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log(res.data.data.pantry_ids)
            setData({
                ...data,
                room_id: res.data.data.room_id,
                room_name: res.data.data.room_name,
                npk: res.data.data.npk,
                name: res.data.data.name,
                division: res.data.data.division,
                department: res.data.data.department,
                start_date: res.data.data.start_date,
                end_date: res.data.data.end_date,
                kind_meeting: res.data.data.kind_meeting,
                topic_meeting: res.data.data.topic_meeting,
                type: res.data.data.type,
                priority_id: res.data.data.priority_id,
                priority_name: res.data.data.priority_name,
                pantries: res.data.data.pantry_ids && res.data.data.pantry_ids,
                vendors: res.data.data.vendor_ids && res.data.data.vendor_ids
            })

            setLoading(false)

        }).catch(err => {
            
            setLoading(false)

            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }

    const dataview = [
        {
            title: ('Room Name'),
            description: data && data.room_name
        },
        {
            title: ('NPK'),
            description: data && data.npk
        },
        
        {
            title: ('Division'),
            description: data && data.division
        },
        {
            title: ('Topic Meeting'),
            description: data && data.topic_meeting
        },
        {
            title: ('Start Time'),
            description: data && moment(data.start_date).format('HH:mm')
        },
    ];

    const dataview2 = [
        {
            title: ('Name'),
            description: data && data.name
        },
        {
            title: ('Department'),
            description: data && data.department
        },
        {
            title: ('Kind Meeting'),
            description: data && data.kind_meeting
        },
        {
            title: ('Date'),
            description: data && moment(data.start_date).format('DD MMMM YYYY')
        },
        {
            title: ('End Time'),
            description: data && moment(data.end_date).format('HH:mm') 
        },
    ];

    const columnsInvitation = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: false
        },
        {
            title: 'Department',
            dataIndex: 'department',
            sorter: false
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            sorter: false,
        }
    ]

    const columnsFood = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            sorter: false
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: false
        }
    ]

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>View Meeting</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={`/room-reservation/list/${data.room_id && data.room_id}`}>Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>View Meeting</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                                <Col md={12} sm={24} xs={24}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataview}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                title={<strong>{item.title}</strong>}
                                                description={item.description ? item.description : '-'}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataview2}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                title={<strong>{item.title}</strong>}
                                                description={item.description ? item.description : '-'}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                
                <Card className="body-data">
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Title level={4}>Partisipants</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <div>
                                <Table
                                    columns={columnsInvitation}
                                    dataSource={data.vendors}
                                    rowKey={(data) => data.id}
                                    loading={loading}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>

                <Card className="body-data">
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Title level={4}>Pantries</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                        <div>
                            <Table
                                columns={columnsFood}
                                dataSource={data.pantries}
                                rowKey={(data) => data.id}
                                loading={loading}
                            />
                        </div>
                        </Col>
                    </Row>
                </Card>
            
            </Content>
        </Fragment>
    )
}

export default ViewRoomReservation