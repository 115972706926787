import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Col, Breadcrumb, Typography, Layout, Table, Tag, Space, message, Tooltip } from 'antd'
import {Redirect, Link} from 'react-router-dom'
import Axios from 'axios'
import { url } from '../../config/global'
import { HomeFilled, DropboxOutlined, DropboxSquareFilled } from '@ant-design/icons'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'

const { Title, Text } = Typography
const { Content } = Layout
const { Meta } = Card;

function Dashboard() {

    const [data, setData] = useState([])

    const getData = () => {
      Axios.get(`${url}/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => {
        setData(res.data.data)
      }).catch(err => {
        if (err.response) {
          message.error(err.response.data.message)
        }
      })
    }

    useEffect(() => {
      getData()
    }, [])
    
    if (!localStorage.getItem('token')) {
      return <Redirect to="/login" />
    }

    const firstOfDate = moment(new Date()).startOf('month').format('D')
    const endOfDate = moment(new Date()).endOf('month').format('D')

    let dates = [];
    let datas = [];
    let datas2 = [];
    let datas3 = [];

    for (let i = parseInt(firstOfDate); i <= parseInt(endOfDate); i++) {
      dates.push(i)
      datas.push(Math.floor(Math.random() * 10000))
      datas2.push(Math.floor(Math.random() * 10000))
      datas3.push(Math.floor(Math.random() * 10000))
    }
    // console.log(dates, datas)

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="User" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>
            <Content className="content">
                <Row gutter={[20, 30]}>
                    <Col md={8} sm={24}>
                      <Card actions={[
                            <Link to="/details">View Details</Link>
                          ]}>
                        <Meta
                            avatar={
                              <HomeFilled style={{fontSize: 25}} />
                            }
                            title="Warehouse Material"
                            description={<Title level={4}>1,600</Title>}
                          />
                      </Card>
                    </Col>
                    <Col md={8} sm={24}>
                      <Card actions={[
                            <Link to="/details">View Details</Link>
                          ]}>
                        <Meta
                            avatar={
                              <DropboxOutlined style={{fontSize: 25}} />
                            }
                            title="Finish Goods (OK)"
                            description={<Title level={4}>700</Title>}
                          />
                      </Card>
                    </Col>
                    <Col md={8} sm={24}>
                      <Card actions={[
                            <Link to="/details">View Details</Link>
                          ]}>
                        <Meta
                            avatar={
                              <DropboxOutlined style={{fontSize: 25}} />
                            }
                            title="Finish Goods (NG)"
                            description={<Title level={4}>400</Title>}
                          />
                      </Card>
                    </Col>
                    <Col xs={24}>
                          <Card title="Ratio OK and NG" extra={ <Text strong>June 2020</Text>}>
                        <Bar data={{
                          labels: dates,
                          datasets: [{
                              label: 'Finish Goods OK',
                              data: datas,
                              backgroundColor: 'rgba(8, 146, 68, .7)',
                              borderColor: 'rgba(8, 146, 68, 1)',
                              borderWidth: 1
                          }, {
                              label: 'Finish Goods NG',
                              data: datas2,
                              backgroundColor: 'rgba(255, 70, 71, .7)',
                              borderColor: 'rgba(255, 70, 71, 1)',
                              borderWidth: 1
                          }, {
                            label: 'Plan',
                            data: datas3,
                            backgroundColor: 'transparent',
                            borderColor: 'rgba(24, 144, 255, 1)',
                            borderWidth: 1,
                            type: 'line'
                        }]
                        }}
                        options={{
                          scales: {
                            xAxes: [{
                              gridLines: {
                                display: false
                              },
                              stacked: true
                            },
                          ]
                          },
                        }}
                        />  
                      </Card>
                    </Col>
                    <Col md={12} sm={24}>
                        <Card title="Output Production Plan: 800Pcs" extra={<Text strong>Actual: 750 Pcs</Text>}>
                          <Table size="small" dataSource={[
                            {
                              no: '1',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '2',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '3',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '4',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '5',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            }
                          ]} columns={[
                            {
                              title: 'No',
                              dataIndex: 'no',
                              key: 'no',
                              width: 40
                            },
                            {
                              title: 'Serial Number',
                              dataIndex: 'serial_number',
                              key: 'serial_number'
                            },
                            {
                              title: 'Part Number',
                              dataIndex: 'part_number',
                              key: 'part_number'
                            },
                            {
                              title: 'Description',
                              dataIndex: 'description',
                              key: 'description',
                              ellipsis: {
                                showTitle: false
                              },
                              render: description => (
                                <Tooltip placement="topLeft" title={description}>
                                  {description}
                                </Tooltip>
                              ),
                            }
                          ]}
                          rowKey={data => data.no}
                          />
                        </Card>
                    </Col>
                    <Col md={12} sm={24}>
                        <Card title="Data NG Product: 10 Pcs">
                          <Table size="small" dataSource={[
                            {
                              no: '1',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '2',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '3',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '4',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            },
                            {
                              no: '5',
                              serial_number: '12345678',
                              part_number: 'PN0987654321',
                              description: 'Rr Seat Entertainment 10 Inch'
                            }
                          ]} columns={[
                            {
                              title: 'No',
                              dataIndex: 'no',
                              key: 'no',
                              width: 40
                            },
                            {
                              title: 'Serial Number',
                              dataIndex: 'serial_number',
                              key: 'serial_number'
                            },
                            {
                              title: 'Part Number',
                              dataIndex: 'part_number',
                              key: 'part_number'
                            },
                            {
                              title: 'Description',
                              dataIndex: 'description',
                              key: 'description',
                              ellipsis: {
                                showTitle: false
                              },
                              render: description => (
                                <Tooltip placement="topLeft" title={description}>
                                  {description}
                                </Tooltip>
                              ),
                            }
                          ]}
                          rowKey={data => data.no}
                          />
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>

    )
}

export default Dashboard
