import { message } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { url } from '../../config/global';
import PulseLoader from "react-spinners/PulseLoader";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function Sso(props) {
  let [loading, setLoading] = useState(true);
  let [urlSso, setUrlSso] = useState('');

  let query = useQuery();
  useEffect(() => {
    loginSSO();
  }, [])

  const loginSSO = () => {
    Axios.post(`${url}/login-sso`, {
      token: query.get('token')
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("fullname", res.data.data.full_name);
        localStorage.setItem(
          "avatar",
          res.data.data.photo ? res.data.data.photo_url : ""
        );
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        localStorage.setItem("redirect", res.data.redirect);
        localStorage.setItem('email', res.data.data.email)
        localStorage.setItem('reset_token', res.data.data.reset_token)
        localStorage.setItem('user_data', JSON.stringify(res.data.data))

        message.success(res.data.message);
        props.history.push(res.data.redirect);
      })
      .catch((err) => {
        if (err.response) {
          props.history.push('/login')
          message.error(err.response.data.message, 5);
        }
      });
  }

  return (
    <div style={stylewrap}>
      <div className="sweet-loading" style={loader}>
        <PulseLoader loading={loading} size={20} color={"#03A000"} />
      </div>
    </div>
  )

}
const stylewrap = {
  textAlign: "center",
  position: "fixed",
  top: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "#ffffff",
  zIndex: 1000,
  opacity: "0.5"
}
const loader = {
  position: "relative",
  top: "15rem"
}

export default Sso
