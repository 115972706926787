import React, { useState, useEffect, Fragment } from 'react'
import { Card, Typography, Menu, Dropdown, Layout, Row, Col, Breadcrumb, Button, Input, Table, Modal, message, Space, Avatar, DatePicker, Spin } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global'
import { UserOutlined, MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import './MeetingList.less'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout


function MeetingList() {

    const [keyword, setKeyword] = useState('')
    const [roomList, setRoomList] = useState([])
    const [roomDetail, setRoomDetail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))

    const handleSearch = (value) => {
        setKeyword(value)
        setPage(1)
    }

    useEffect(() => {
        getData(keyword, page, sort, date)
    }, [keyword, page, sort, date])

    const getData = (keyword, page, sort) => {
        setLoading(true)
        Axios.get(`${url}/meeting-list`, {
            params: {
                keyword,
                columns: [
                    'name',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                date: date
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setRoomList(res.data.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const columns = [
        {
            title: 'Room Name',
            dataIndex: 'room_name',
            sorter: false
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            children: [
                {
                    title: 'Date',
                    dataIndex: 'date',

                },
                {
                    title: 'Start time',
                    dataIndex: 'start_time',
                },
                {
                    title: 'End time',
                    dataIndex: 'end_time',

                }
            ]
        },
        {
            title: 'Jenis Meeting',
            dataIndex: 'kind_meeting',
            sorter: false,
        },
        {
            title: 'Topik Meeting',
            dataIndex: 'topic_meeting',
            sorter: false,
        },
        {
            title: 'PIC Name',
            dataIndex: 'name',
            sorter: false,
        }, {
            title: 'Status',
            dataIndex: 'status',
            sorter: false,
        }
    ]

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Meeting List" extra={moment(new Date()).format('DD MMMM YYYY')}>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Card className="body-data">
                    <Row type="flex" gutter={[10, 20]}>
                        <Col xs={24} md={12} style={{ marginLeft: 'auto' }}>
                            <Row type="flex" gutter={[10, 20]}>
                                <Col xs={24} md={12}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        defaultValue={moment(new Date())}
                                        onChange={(value) => setDate(moment(value).format('YYYY-MM-DD'))}
                                        format={'YYYY-MM-DD'}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Search
                                        placeholder="Search ..."
                                        // onSearch={(value) => handleSearch(value)}
                                        onChange={(e) => handleSearch(e.currentTarget.value)}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} style={{ overflowX: 'auto' }}>
                            <Table
                                columns={columns}
                                dataSource={roomList}
                                rowKey={(data) => data._id}
                                onChange={handleTableChange}
                                pagination={{ pageSize: 10, current: page, total: roomList.total, showSizeChanger: false }}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Card>
            </Content>
        </Fragment>
    )
}



export default MeetingList
