import React, { useState, useEffect, Fragment } from 'react'
import { Card, Typography, Menu, Dropdown, Layout, Row, Col, Button, Input, Table, Modal, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url } from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ExportOutlined, InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import './Priority.less'
import fileDownload from 'js-file-download';

const { Search } = Input
const { Content } = Layout


function Priority() {

    const [keyword, setKeyword] = useState('')
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })

    const [buttonAccess, setButtonAccess] = useState(null);

    const handleSearch = (value) => {
        setKeyword(value)
		setPage(1)
    }

    useEffect(() => {
        getData(keyword, page, sort)

        const pathname = window.location.pathname.split("/");
        const currentUrl = pathname[1];

        const permissions =
            localStorage.getItem("permissions") &&
            JSON.parse(localStorage.getItem("permissions"));

        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl
            );
            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren = findChildPermission.children
                        .filter((x) => x !== null)
                        .filter((x) => x.permission_type === "button");
                    if (findButtonAccessChildren) {
                        setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
                    }
                }
            }
        }
    }, [keyword, page, sort])

    const exportData = () => {
        setLoading(true)

        Axios.get(`${url}/priority/export`, {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/vnd.ms-excel",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then((res) => {
                message.success('File downloaded')
                fileDownload(res.data, "Export Priority" + ".xlsx");
            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
            }).finally(() => {
                setLoading(false)
            })
    }

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/priority-with-detail`, {
            params: {
                keyword,
                columns: [
                    'level',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log(res)
            setUsers(res.data)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = (id) => {

        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id)
            }
        })
    }

    const confirmDelete = (id) => {

        setLoading(true)
        Axios.delete(`${url}/priority/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            getData(keyword, page, sort)
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }


    const expandedRowRender = (row) => {
        const columns = [
            {
                title: 'No',
                dataIndex: 'no',
                key: 'no',
                sorter: true,
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true
            },
            {
                title: '',
                dataIndex: '_id',
                align: 'right',
                sorter: false,
                width: 100,
                render: (id) => {
                    return (
                        <Fragment>
                            <Dropdown className="pointer" overlay={
                                <Menu>
                                    {buttonAccess &&
                                        buttonAccess.find((x) => x.url === "priority/view") && (
                                            <Menu.Item key="0">
                                                <Link to={`/priority/view/${id}`}><InfoCircleOutlined /> View</Link>
                                            </Menu.Item>
                                        )}
                                    {buttonAccess &&
                                        buttonAccess.find((x) => x.url === "priority/edit") && (
                                            <Menu.Item key="1">
                                                <Link to={`/priority/edit/${id}`}><EditOutlined /> Edit</Link>
                                            </Menu.Item>
                                        )}
                                    {buttonAccess &&
                                        buttonAccess.find((x) => x.url === "priority/delete") && (
                                            <Menu.Item key="3">
                                                <span onClick={() => handleDelete(id)}><DeleteOutlined />Delete</span>
                                            </Menu.Item>
                                        )}
                                </Menu>
                            } trigger={['click']}>
                                <span className="pointer" onClick={e => e.preventDefault()}>
                                    <MoreOutlined />
                                </span>
                            </Dropdown>
                        </Fragment>
                    )
                }
            }
        ]

        return <Table columns={columns} dataSource={row.data} pagination={false} rowKey={(data) => data._id} />
    }


    const columns = [
        {
            title: '',
            dataIndex: 'name',
        },
    ]

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Priority" extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>

            <Content className="content">
                <Card className="body-data">
                    <div className='wrapperBtn'>
                        <div className='left'>
                            {buttonAccess &&
                                buttonAccess.find((x) => x.url === "priority/create") && (
                                    <Link to="/priority/create">
                                        <Button type="primary" className='btn'><PlusOutlined />Add Priority</Button>
                                    </Link>
                                )}
                            {buttonAccess &&
                                buttonAccess.find((x) => x.url === "priority/export") && (
                                    <Button type="primary" className='btn' onClick={exportData}>
                                        <ExportOutlined />
                                        Export
                                    </Button>
                                )}
                        </div>
                        <div className='right'>
                            <Search
                                placeholder="Search ..."
                                // onSearch={(value) => handleSearch(value)}
                                onChange={(e) => handleSearch(e.currentTarget.value)}
                            />
                        </div>
                    </div>
                    <Row>
                        <Col xs={24} style={{ overflowX: 'auto' }}>
                            <Table
                                columns={columns}
                                dataSource={users}
                                rowKey={(data) => data.id}
                                expandable={{ expandedRowRender }}
                                onChange={handleTableChange}
                                pagination={{ pageSize: 10, current: page, total: users.total, showSizeChanger: false }}
                                loading={loading}

                            />
                        </Col>
                    </Row>
                </Card>
            </Content>
        </Fragment>
    )
}



export default Priority
