import React, { useState, useEffect } from 'react'
import { Input, Button, message, Form, Row, Col } from 'antd'
import './Login.less'
import { UserOutlined, LockOutlined, CopyrightCircleOutlined } from '@ant-design/icons'
import Axios from 'axios'
import { url } from '../../config/global'
import { Link } from 'react-router-dom'

function Login(props) {
    const [login, setLogin] = useState({username: '', password: ''})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = (name, value) => {
        setError(null)
        setLogin({
            ...login,
            [name]: value
        })
    }

    const handleLogin = async () => {
        setLoading(true)
        await Axios.post(`${url}/login`, {
            username: login.username,
            password: login.password
        }).then(res => {

            localStorage.setItem('token', res.data.token)
            localStorage.setItem('fullname', res.data.data.full_name)
            localStorage.setItem('avatar', res.data.data.photo_url ? res.data.data.photo_url : '')
            localStorage.setItem('permissions', JSON.stringify(res.data.permissions))
            localStorage.setItem('redirect', res.data.redirect)

            localStorage.setItem('email', res.data.data.email)
            localStorage.setItem('reset_token', res.data.data.reset_token)

            localStorage.setItem('user_data', JSON.stringify(res.data.data))

            message.success(res.data.message)
            setLoading(false)
            props.history.push(res.data.redirect)

        }).catch(err => {
            message.error(err.response.data.message)
            setLoading(false)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
        })
    }

    return (
        <div className='main'>
            <Row>
                <Col lg={17} md={0} sm={0} xs={0}> 
                    <img
                        src={require('../../assets/img/banner.png')}
                        className='bg-login'
                    />
                    <div className='bottom-text'>
                    <CopyrightCircleOutlined /> 2021 PT. Dharma Polimetal
                    </div>
                </Col>
                <Col lg={7} md={24} sm={24} xs={24}>
                    <div className='right-content'>
                        <img    
                            src={require('../../assets/img/banner-login.png')}
                            className='img-logo'
                            alt='bg-login'
                        />
                        <div className='signin-text'>SignIn</div>
                        <Form onFinish={handleLogin}>
                            <Form.Item
                                validateStatus={error && error.username ? 'error' : false }
                                help={error && error.username ? error.username[0] : false }
                                >
                                <Input
                                    className="login-input"
                                    prefix={<UserOutlined />}
                                    placeholder="Username"
                                    onChange={(e) => handleChange('username', e.target.value)}
                                    value={login.username}

                                />
                            </Form.Item>
                            <Form.Item
                                validateStatus={error && error.password ? 'error' : false }
                                help={error && error.password ? error.password[0] : false }
                                >
                                    <Input.Password 
                                        className="login-input"
                                        prefix={<LockOutlined />}
                                        placeholder="Password"
                                        onChange={(e) => handleChange('password', e.target.value)}
                                        value={login.password}
                                    />
                                </Form.Item>
                            <div className='login-box'>
                                <Button htmlType='submit' loading={loading} type="primary" className="button-login">
                                    Login
                                </Button>
                                <Link to='/forgot-password'>
                                    <div className='forgot-text'>Forgot Password</div>
                                </Link>
                            </div>
                            {/* <div className='register-text'>Don't have an account yet ? Register below</div>
                            <div className='register-box'>
                                <Button type="primary" onClick={() => props.history.push('/register')}>
                                    Register
                                </Button>
                            </div> */}
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login
